import React from 'react';

export const TriangleIcon = () => {
    return (
        <div className="triangle">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M8.91377 5.54643L6.04788 10.8293L1.21822 5.99963L6.08253 1.13532L8.91377 5.54643Z" fill="#F4F5F7" stroke="#E1E6ED" />
                <rect x="10.252" width="12" height="4.42712" transform="rotate(90 10.252 0)" fill="#F4F5F7" />
            </svg>
        </div>
    );
};