

import React from 'react';

export const DashboardIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9894 4.32858V4.34858C13.9894 5.01858 14.4394 5.57858 15.0694 5.77858C17.9194 6.66858 19.9894 9.31858 19.9894 12.4586C19.9894 12.9786 19.9294 13.4686 19.8194 13.9486C19.6794 14.5886 19.9394 15.2486 20.5094 15.5886L20.5194 15.5986C21.3794 16.0986 22.4994 15.6486 22.7294 14.6886C22.8994 13.9686 22.9894 13.2186 22.9894 12.4586C22.9894 7.95858 20.0094 4.13858 15.9094 2.88858C14.9594 2.59858 13.9894 3.32858 13.9894 4.32858ZM11.9294 19.3786C8.93942 18.9486 6.50942 16.5186 6.06942 13.5386C5.52942 9.93858 7.72942 6.76858 10.8994 5.77858C11.5394 5.58858 11.9894 5.01858 11.9894 4.34858V4.32858C11.9894 3.32858 11.0194 2.59858 10.0594 2.88858C5.54942 4.26858 2.39942 8.74858 3.07942 13.8486C3.66942 18.2286 7.20942 21.7686 11.5894 22.3586C14.7294 22.7786 17.6294 21.7486 19.7194 19.8286C20.4594 19.1486 20.3294 17.9386 19.4594 17.4386C18.8794 17.0986 18.1594 17.2086 17.6594 17.6586C16.1894 18.9986 14.1494 19.7086 11.9294 19.3786Z" fill="#A1ABB2" />
        </svg>
    );
};