import React, { useEffect, useState } from "react";
import SignInPage from "./pages/SignInPage";
import Dashboard from "./pages/Invoices";
import Order from "./pages/Orders";
import Licenses from "./pages/TradesPage";
import AddOrder from "./pages/AddOrder";
import CompaniesPage from "./pages/CompaniesPage";
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationItem from "./infrastructure/components/TopNavBarComponent/notification-modal/notification-item"
import { useSelector, useDispatch } from "react-redux";
import { getToken, getAccessToken, getTokenInHeaders } from "./api/selectors/tokenSelector";
import {
  removeToken,
  removeUserData,
  removeExpiredTime,
  removeRefreshToken,
} from "./api/services/Auth";
import {
  logoutFinish,
  setToken,
  setExpiredTime,
  signIn,
  setTokenInHeaders,
} from "./api/actionCreators/authActionCreator";
import { getLocalstorageToken, isTokenPresent } from "./api/services/Auth";
import axios from "axios";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { enqueueSnackbar } from "notistack";

import "./App.scss";

const App = () => {
  const dispatch = useDispatch();

  const token = useSelector(getToken);
  const accessToken = useSelector(getAccessToken);
  const tokenInHeader = useSelector(getTokenInHeaders);
  const tokenFromLocalStorage = isTokenPresent() ? getLocalstorageToken() : "";




  // const [keycloakToken, setKeycloakToken] = useState('');
  const [stompClient, setStompClient] = useState(null);
  const [isSocketAdded, setIsSocketAdded] = useState(false);
  // const [connected, setConnected] = useState(false);
  // const [messages, setMessages] = useState([]);
  // console.log("tokenInHeader+++++++++++++ ", tokenInHeader, token)
  useEffect(() => {
    tokenInHeader && !isSocketAdded && connect()
    !tokenInHeader && isSocketAdded && disconnect()
  }, [tokenInHeader, isSocketAdded]);

  // useEffect(() => {
  //   isSocketAdded && accessToken.length === 0 && disconnect()
  // }, [accessToken]);

  useEffect(() => {
    return () => {
        if (stompClient && !tokenInHeader) {
            stompClient.disconnect();
            console.log("Disconnected at component unmount");
        }
    }
}, [stompClient, tokenInHeader]);

  const connect = () => {
    setIsSocketAdded(true)
    const socket = new SockJS('http://fxdirect.redriverapps.net:9090/ws'); // Update with your WebSocket endpoint
    const client = Stomp.over(socket);
    client.connect({ 'Auth-Token': tokenFromLocalStorage }, frame => {
      client.subscribe('/topic/notifications', message => {
      });
      client.subscribe('/user/queue/notifications', message => {
        const res = JSON.parse(message.body)
        enqueueSnackbar(<NotificationItem {...res} showNotificationModal/>)
      });
    });
    setStompClient(client);
  };

  const disconnect = () => {
    if (stompClient !== null) {
      stompClient.disconnect();
      setIsSocketAdded(false)
      console.log("Disconnected");
    }
  };



  useEffect(() => {
    // refreshToken()
    if (!token && !accessToken) {
      removeExpiredTime();
      removeRefreshToken();
      dispatch(logoutFinish());
      dispatch(setToken({ accessToken: "", refreshToken: "", expiresIn: "" }));
      dispatch(setExpiredTime(null));
      dispatch(signIn(null));
      removeToken();
      removeUserData();
    } else {
      axios.defaults.headers.common["Authorization"] = "Bearer " + tokenFromLocalStorage;
      dispatch(setTokenInHeaders(true));
    }
  }, [token]);

  return (
    <Switch>
      <Route path="/" exact component={SignInPage} />
      <Route path="/app/invoices" exact component={Dashboard} />
      {/* <Route path="/app/kyc-data" exact component={KycData} /> */}
      <Route path="/app/orders" exact component={Order} />
      <Route path="/app/add-order" exact component={AddOrder} />
      <Route path="/app/order/details/:orderId" exact component={AddOrder} />
      <Route path="/app/trades" exact component={Licenses} />
      {/* <Route path="/app/licenses/:customerId" exact component={CustomerLicenses} /> */}
      <Route path="/app/company" exact component={CompaniesPage} />
      {/* <Route path="/app/customers/info/:customerId" exact component={CustomerAddEdit} />
            <Route path="/app/customers/new" exact component={CustomerAddEdit} /> */}
      <Redirect to="/app/invoices" />
    </Switch>
  );
};

export default App;
