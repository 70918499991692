import { ListDottIcon } from "../svg-icons/icon-list-dott"

const renderErorLabel = (errLabel, clName) => {
    return <div className={`error-label ${clName}`}>
        <ListDottIcon />
        {errLabel}
    </div>
}

export const UseErrorLabel = (err) => {
    let errLabel;

    switch (err) {
        case "ERROR":
        case "OVERDUE":
            errLabel = renderErorLabel(err, "red");
            break;
        case "EXPIRED":
        case "CANCELED":
        case "DELETED":
            errLabel = renderErorLabel(err, "gray");
            break;
        case "FILLED":
        case "NEW":
        case "ACTIVE":
            errLabel = renderErorLabel(err, "blue");
            break;
        case "RECEIVED":
        case "COMPLETED":
        case "PAYED":
            errLabel = renderErorLabel(err, "green");
            break;
        case "FILLING":
        case "INVOICED":
            errLabel = renderErorLabel(err, "yellow");
            break;
        case "PROCESSING":
            errLabel = renderErorLabel(err, "orange");
            break;
        default:
            errLabel = "";
            break;

    }

    return errLabel
}