import { RootState } from '../../interfaces'

const getAuthReducerState = (state: RootState) => {
    return state?.AuthReducer;
};

export const getIsAuthFetching = (state: RootState) => {
    return getAuthReducerState(state).isAuthFetching;
};

export const getToken = (state: RootState) => {
    return getAuthReducerState(state).token;
};

export const getAuthData = (state: RootState) => {
    return getAuthReducerState(state).authData;
};