import React from "react";
import { IWarningBannerProps } from "./types";
import { ManufactureIcon } from "../../../svg-icons/icon-manufacture";
import { PauseIcon } from "../../../svg-icons/icon-pause";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./warning-banner.module.scss";

function WarningBanner({ message, disabled, id }: IWarningBannerProps) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        [`${styles.warning_wrapper}`]: true,
        [`${styles.background_gray}`]: disabled,
      })}
      id={id}
    >
      <label>
        {disabled ? <PauseIcon /> : <ManufactureIcon /> }
        {message}
      </label>
    </div>
  );
}

export default WarningBanner;
