import React, { useState, useEffect } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import StatisticContainer from "../../infrastructure/components/StatisticContainer";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import styles from "./invoices.module.scss";
import EmptyPageContent from "../../infrastructure/components/EmptyPageContent";
import { setPageReloaded } from '../../api/actionCreators/authActionCreator';
import { getTokenInHeaders } from '../../api/selectors/tokenSelector'

const Invoices = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [accountData, setAccountData] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tokenInHeader = useSelector(getTokenInHeaders)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/accounts`);
        setAccountData(response.data.accountList);
      } catch (error) {
        console.error("Error fetching account data:", error);
        dispatch(setPageReloaded())
      }
    };

    tokenInHeader && fetchData();
  }, [tokenInHeader]);

  // const renderAddNewInvoce = () => {
  //     return (
  //         <Drawer
  //             anchor={"right"}
  //             open={isOpen}
  //             onClose={() => setIsOpen(false)}
  //         >
  //             <div className={styles.settings_drawer_wrapper}>
  //                 <div>
  //                     <div className={styles.drawer_title_container}>
  //                         <div className={styles.drawer_title}>Добавить новый счет</div>
  //                         <IconButton onClick={() => setIsOpen(false)} className={styles.drawer_close_icon}>
  //                             <CloseRemoveIcon />
  //                         </IconButton>
  //                     </div>
  //                     <div className={styles.drawer_content}>
  //                         <div className={styles.setting_content_wrapper}>
  //                             <FormLabeledInput
  //                                 label="Номер счёта"
  //                                 type="text"
  //                             // onChange={''}
  //                             />
  //                         </div>
  //                     </div>
  //                 </div>

  //                 <div className={styles.buttons}>
  //                     <Button
  //                         variant="outlined"
  //                         onClick={() => { }}
  //                         className={styles.drawer_btn}
  //                         // disabled={isFetching}
  //                         // className={classNames({
  //                         //     [`${styles.no_margin}`]: isFetching,
  //                         //     [`${styles.saved}`]: isSaved,
  //                         // })}
  //                     >
  //                         Save
  //                         {/* {!isFetching && !isSaved ? <SaveIcon /> :
  //                                 isSaved ? <CheckedIcon /> :
  //                                     <CircularProgress size={20} sx={{ margin: 0 }} />} */}
  //                     </Button>
  //                 </div>

  //             </div>
  //         </Drawer>
  //     )
  // }

  return (
    <>
      {/* {renderAddNewInvoce()} */}
      <AppMainLayout navBarTitle={t("Invoices_page")} noBackground>
        <div className={`${styles.statistic_parent}`} id={'statistic_parent'}>
          {accountData ? (
            accountData.map((data, index) => (
              <StatisticContainer
                title={t("Count") + data.accountId}
                invoiceNumber={data.number}
                amount={data.balance}
                currency={data.currency}
                color={"#2F80ED"}
                key={index}
                id={'statistic_parent_count_' + index}
              />
            ))
          ) : (
            <EmptyPageContent />
          )}
          {/* Remove  for MVP */}
          {/* <div className={`${styles.new_invoice_cta_wrapper}`} onClick={() => setIsOpen(true)}>
                        <AddIcon />
                        Добавить новый счет
                    </div> */}
        </div>
      </AppMainLayout>
    </>
  );
};

export default Invoices;
