import { RootState } from '../../interfaces'

const getTokenReducerState = (state: RootState) => {
    return state?.TokenReducer;
};

export const getToken = (state: RootState) => {
    return getTokenReducerState(state).token;
};

export const getAccessToken = (state: RootState) => {
    return getTokenReducerState(state).accessToken;
};

export const getRefreshToken = (state: RootState) => {
    return getTokenReducerState(state).refreshToken;
};

export const getExpiredTime = (state: RootState) => {
    return getTokenReducerState(state).expiredTime;
};

export const getTokenInHeaders = (state: RootState) => {
    return getTokenReducerState(state).tokenInHeaders;
};

export const getReloadPageState = (state: RootState) => {
    return getTokenReducerState(state).reloadPage;
};