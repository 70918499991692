import React from "react";

export const WorkIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="work">
        <path
          id="Vector"
          d="M20 6.59204H16V4.59204C16 3.48204 15.11 2.59204 14 2.59204H10C8.89 2.59204 8 3.48204 8 4.59204V6.59204H4C2.89 6.59204 2.01 7.48204 2.01 8.59204L2 19.592C2 20.702 2.89 21.592 4 21.592H20C21.11 21.592 22 20.702 22 19.592V8.59204C22 7.48204 21.11 6.59204 20 6.59204ZM14 6.59204H10V4.59204H14V6.59204Z"
          fill="#A1ABB2"
        />
      </g>
    </svg>
  );
};
