import React, { useState, useEffect } from 'react';
import { TriangleIcon } from '../../../svg-icons/icon-triangle';
import { ADAPTIVE_WIDTH } from "../../../constants/data"
import classNames from "classnames"
import {ITooltipWrapperProps} from "./types"

import './styles.scss';

const TooltipWrapper = ({ children, text, tooltipTitle, mt0 = false }: ITooltipWrapperProps) => {

    const [show, setShow] = useState(false)

    const getCurrentDimension = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <div className="tooltip-wrapper" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            {show &&
                <div
                    className={classNames({
                        'tooltip-content': true,
                        'left-position': screenSize.width <= ADAPTIVE_WIDTH,
                        'mt0': mt0
                    })}>

                    <TriangleIcon />
                    {tooltipTitle && <div className="tooltip-title">{tooltipTitle}</div>}
                    <div className="tooltip-description"> {text}</div>
                </div>
            }
            {children}
        </div>

    );
};

export default TooltipWrapper;
