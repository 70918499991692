import React from 'react';

import { IHeadingComponentProps } from "./types"
import classNames from "classnames"
import styles from './heading.module.scss';

function HeadingComponent({ headingFirst, headingSecond, textRegular, className }: IHeadingComponentProps) {

    return (
        <div
            className={classNames({
                [`${styles.heading_wrapper}`]: true,
                [className]: !!className
            })}>

            {!!headingFirst && (
                <div className={classNames({
                    [`${styles.headingFirst}`]: true,
                    })}
                >
                    <span>{headingFirst}</span>
                </div>
            )}
            {!!textRegular && (
                <div className={classNames({
                    [`${styles.textRegular}`]: true,
                    })}
                >
                    <span>{textRegular}</span>
                </div>
            )}
        </div>
    );
};

export default HeadingComponent;
