
import React from 'react';
import "./styles.scss"

export const AddIcon = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M24.4998 17.8333H17.8332V24.5C17.8332 25.2333 17.2332 25.8333 16.4998 25.8333C15.7665 25.8333 15.1665 25.2333 15.1665 24.5V17.8333H8.49984C7.7665 17.8333 7.1665 17.2333 7.1665 16.5C7.1665 15.7666 7.7665 15.1666 8.49984 15.1666H15.1665V8.49996C15.1665 7.76663 15.7665 7.16663 16.4998 7.16663C17.2332 7.16663 17.8332 7.76663 17.8332 8.49996V15.1666H24.4998C25.2332 15.1666 25.8332 15.7666 25.8332 16.5C25.8332 17.2333 25.2332 17.8333 24.4998 17.8333Z" fill="#8C969B"/>
        </svg>
    );
};