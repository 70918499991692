import React, { useState } from 'react';
import { Logo } from '../../../assets/icons/logo';

import { INavigationComponentProps } from "./types"
import classNames from "classnames"
import { navigation } from "../../../constants/data"
import styles from './navigation.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { getCurrentLanguage } from '../../../api/selectors/languageSelector';
// import { changeLanguage } from '../../../api/actionCreators/mainActionCreator';

import { ExitIcon } from "../../../svg-icons/icon-exit"
import { useTranslation } from 'react-i18next';
import { getAuthData, getIsAuthFetching } from '../../../api/selectors/authSelector'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button } from 'finergy-kit';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function NavigationComponent({ link, onExit, children, current, className }: INavigationComponentProps) {

    // const language = useSelector(getCurrentLanguage);
    const data = useSelector(getAuthData);
    const isAuthFetching = useSelector(getIsAuthFetching)

    const authData = JSON.parse(data);
    const { t } = useTranslation();

    // const [currentLanguage, setcurrentLanguage] = useState(language);
    const [open, setOpen] = useState(false);

    const pathname = window.location.pathname

    // const handleLanguageChange = (lang) => {
    //     setcurrentLanguage(lang)
    //     changeLanguage(lang);
    // };

    const renderMenu = () => {
        const content = navigation.map(({ linkTitle, url, icon }, index) => {
            const selectedMenu = pathname.includes(url);
            return (
                <Link
                    underline="none"
                    className={classNames({
                        [`${styles.nav_link_wrapper}`]: true,
                        [`${styles.active_nav_menu}`]: selectedMenu,
                    })}
                    key={index}
                    to={`${url}`}
                >
                    {icon}<span className={styles.nav_cta_link}> {t(linkTitle)} </span>
                </Link>
            )

        })

        return content
    }

    const renderExitModal = () => {
        return (
            <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={styles.custom_modal_container}>
                <DialogTitle className={styles.setting_modal_title}>{t("Logout")}</DialogTitle>
                <DialogContent className={styles.modal_content}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('Are_you_sure_logout')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.action_buttons}>

                    <Button
                        onClick={() => onExit()}
                        variant='tertiary'
                        className={styles.cancel_button}
                        label={t('Exit')}
                        size="l"
                        disabled={isAuthFetching}
                        iconPosition="off"
                        icon={<CircularProgress className="circular-progress" size={18} />}
                        iconOnly={isAuthFetching}
                        id={"BACK_BUTTON"}
                    />
                    <Button
                        onClick={() => setOpen(false)}
                        variant='tertiary'
                        label={t('Cancel')}
                        // disabled={isServiceModeFetching}
                        size="l"
                        iconPosition="off"
                        id={"ON_EXIT"}
                    />
                    

                </DialogActions>
            </div>

        </Dialog>
        )
    }

    return (
        <div
            className={classNames({
                [`${styles.nav_wrapper}`]: true,
                [className]: true
            })}>
                {renderExitModal()}
            <div className={styles.top_navigation_wrapper}>
                <div className={styles.nav_logo}>
                    <Logo />
                </div>

                <div className={styles.divider} />

                <div>
                    <div className={styles.nav_menu}>
                        {renderMenu()}
                    </div>
                    <div
                        onClick={() => setOpen(true)}
                        className={classNames({
                            [`${styles.nav_link_wrapper}`]: true,
                            [`${styles.nav_link_exit}`]: true
                        })}
                    >
                        <ExitIcon /><span>{t('Exit')}</span>
                    </div>
                </div>
            </div>
            <div className={styles.navbar_footer}>
                <div className={styles.block_item}>
                    <div className={styles.support}> {t('Support')}: <a href="tel:+37322555544">+373 22 555 544</a></div>
                    <div className={styles.copyright}> Build by <a href="https://finergy.md/">Finergy Tech.</a> All rights reserved.</div>
                </div>
            </div>
        </div>
    );
};

export default NavigationComponent;
