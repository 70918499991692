import React, { useState, useEffect, useRef } from "react";
import { ITopNavBarComponentProps } from "./types";
import { NotificationsIcon } from "../../../svg-icons/icon-notifications";
import { SettingsIcon } from "../../../svg-icons/icon-settings";
import Drawer from "@mui/material/Drawer";
import FormLabeledInput from "../FormComponents/FormLabeledInput/FormLabeledInput";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
import styles from "./top-nav-bar.module.scss";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { CloseRemoveIcon } from "../../../svg-icons/icon-close-remove";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { PersonIcon, KeyIcon, EyeIcon, LanguageIcon } from "finergy-kit";
import Link from "@mui/material/Link";
import { useSelector, useDispatch } from "react-redux";
import { getAuthData } from "../../../api/selectors/authSelector";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../../../api/selectors/languageSelector";
import { getNotificationModalState } from "../../../api/selectors/mainSelector";
import {
  changeLanguage,
  openNotificationModal,
} from "../../../api/actionCreators/mainActionCreator";
import SettingsModal from "./settings-modal";
import NotificationModal from "./notification-modal";
import { getToken } from "../../../api/selectors/tokenSelector";

function TopNavBarComponent({
  navBarTitle,
  linkTitle,
  link,
  onClick,
  children,
  current,
  className,
}: ITopNavBarComponentProps) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  // const [isOpenNotifications, openNotificationModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getAuthData);
  const authData = JSON.parse(data);

  const language = useSelector(getCurrentLanguage);
  const modalState = useSelector(getNotificationModalState);
  const token = useSelector(getToken);
  const [currentLanguage, setcurrentLanguage] = useState(language);

  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef, openNotificationModal);

  const handleLanguageChange = (lang) => {
    setcurrentLanguage(lang);
    changeLanguage(lang);
  };

  useEffect(() => {
    !authData?.login && !token && setRedirect(true);
  }, [authData]);

  const renderAddNewInvoce = () => {
    return (
      <Drawer anchor={"right"} open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={styles.settings_drawer_wrapper} id="SETTINGS_DRAWER_WRAPPER">
          <div className={styles.drawer_title_container} id="DRAWER_TITLE_CONTAINER">
            <div className={styles.drawer_title} id="SETTINGS-WRAPPER">{t("Setting")}</div>
            <IconButton
              onClick={() => setIsOpen(false)}
              className={styles.drawer_close_icon}
              id="SETTING"
            >
              <CloseRemoveIcon />
            </IconButton>
          </div>
          <div className={styles.select_lang_container_parent} id="SELECT_LANG_CONTAINER_PARENT">
            <FormControl className={styles.select_lang_container} id="SELECT_LANG_CONTAINER">
              {/* <InputLabel id="demo-simple-select-label">{t('Employment')}</InputLabel> */}
              <LanguageIcon />
              <Select
                className="select-lang-selector"
                labelId="demo-simple-select-label"
                id="DEMO-SIMPLE-SELECT"
                value={currentLanguage}
                onChange={(e) => handleLanguageChange(e.target.value)}
              >
                <MenuItem value={"ro"} key={0} id="LANG-ITEM-RO">
                  RO
                </MenuItem>
                <MenuItem value={"ru"} key={1} id="LANG-ITEM-RU">
                  RU
                </MenuItem>
                <MenuItem value={"en"} key={2} id="LANG-ITEM-EN">
                  EN
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Drawer>
    );
  };

  const changeStateNotifModal = () => {
    setTimeout(() => {
      dispatch(openNotificationModal(false));
    }, 200);
    // if(place && !isOpenNotifications) {
    //   openNotificationModal(true)
    // } else{
    //   isOpenNotifications && openNotificationModal(!isOpenNotifications)
    // }
  };

  return (
    <>
      {/* {renderAddNewInvoce()} */}
      <SettingsModal {...{ setShowSettingsModal, showSettingsModal }} />
      {redirect && <Redirect to="/" />}
      {/* 
      <h2>WebSocket Test Page</h2>
      Keycloak Token: <input type="text" value={keycloakToken} onChange={e => setKeycloakToken(e.target.value)} /><br />
      <button onClick={() => connect()} disabled={connected}>Connect</button>
      <button onClick={() => disconnect()} disabled={!connected}>Disconnect</button><br />
      <div id="response">
        {connected ? 'Connected' : 'Disconnected'}
        {messages.map((msg, index) => <div key={index}>{msg.titleRu}</div>)}
      </div> */}

      <div
        className={classNames({
          [`${styles.top_nav_bar_wrapper}`]: true,
          [className]: true,
        })}
        id="TOP_NAV_BAR_WRAPPER"
      >
        <div className={styles.top_bar_page_name} id="TOP_BAR_PAGE_NAME">
          <Breadcrumbs aria-label="breadcrumb" className={styles.breadcrumbs} id="BREADCRUMBS">
            <Typography color="text.primary" id="NAVBARTITLE">{navBarTitle}</Typography>
          </Breadcrumbs>
        </div>
        <div className={styles.top_bar_page_user_wrapper} id="TOP_BAR_PAGE_USER_WRAPPER">
          <div
            className={classNames({
              [`${styles.notification_icon_wrapper}`]: true,
              [`${styles.notification_selected}`]: modalState,
            })}
            id="NOTIFICATION_ICON_WRAPPER"
          >
            <NotificationsIcon
              onClick={() =>
                modalState ? {} : dispatch(openNotificationModal(true))
              }
            />
            {modalState && (
              <NotificationModal onClick={changeStateNotifModal} />
            )}
            {/* <NotificationModal /> */}
          </div>
          <div
            className={styles.settings_icon_wrapper}
            onClick={() => setIsOpen(true)}
            id="SETTINGS_ICON_WRAPPER"
          >
            <SettingsIcon onClick={() => setShowSettingsModal(true)}/>
          </div>
          <div className={styles.user_name_info_wrapper} id="USER_NAME_INFO_WRAPPER">
            <span>{authData?.login}</span>
            <PersonIcon />
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavBarComponent;
