

import React from 'react';

export const ExitIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.7383 15.75C21.0479 15.75 20.4883 16.3096 20.4883 17V19.5H5.48828V17C5.48828 16.3096 4.92864 15.75 4.23828 15.75C3.54793 15.75 2.98828 16.3096 2.98828 17V19.5C2.98828 20.875 4.11328 22 5.48828 22H20.4883C21.8633 22 22.9883 20.875 22.9883 19.5V17C22.9883 16.3096 22.4286 15.75 21.7383 15.75ZM18.3562 11.6321C18.8434 11.1449 18.8434 10.3551 18.3562 9.8679C17.8697 9.38139 17.0811 9.38063 16.5937 9.86619L14.2383 12.2125V3.25C14.2383 2.55964 13.6786 2 12.9883 2C12.2979 2 11.7383 2.55964 11.7383 3.25V12.2125L9.38288 9.86619C8.89543 9.38063 8.10689 9.38139 7.62038 9.8679C7.13321 10.3551 7.13321 11.1449 7.62038 11.6321L12.7899 16.8016C12.8994 16.9112 13.0771 16.9112 13.1867 16.8016L18.3562 11.6321Z" fill="#2F80ED" />
        </svg>
    );
};