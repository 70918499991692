import React from "react";

export const SwapIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="swap_horiz">
        <path
          id="Vector"
          d="M6.13563 11.947L3.35563 14.737C3.16563 14.937 3.16563 15.247 3.35563 15.447L6.13563 18.237C6.44563 18.557 6.98563 18.327 6.98563 17.887V16.087H12.9956C13.5456 16.087 13.9956 15.637 13.9956 15.087C13.9956 14.537 13.5456 14.087 12.9956 14.087H6.98563V12.297C6.98563 11.847 6.44563 11.627 6.13563 11.947ZM20.6456 8.737L17.8656 5.947C17.5556 5.627 17.0156 5.857 17.0156 6.297V8.087H10.9956C10.4456 8.087 9.99564 8.537 9.99564 9.087C9.99564 9.637 10.4456 10.087 10.9956 10.087H17.0056V11.877C17.0056 12.327 17.5456 12.547 17.8556 12.227L20.6356 9.437C20.8356 9.247 20.8356 8.927 20.6456 8.737Z"
          fill="#A1ABB2"
        />
      </g>
    </svg>
  );
};
