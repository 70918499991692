import React, { useEffect, useState } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import EmptyPageContent from "../../infrastructure/components/EmptyPageContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ExitIcon, PlusIcon, Button } from "finergy-kit";
import { TransitionProps } from "@mui/material/transitions";
import { SearchIcon } from "../../svg-icons/icon-search";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccessToken,
  getTokenInHeaders,
} from "../../api/selectors/tokenSelector";
import axios from "../../api/services/Api";
import { setPageReloaded } from "../../api/actionCreators/authActionCreator";
import moment from "moment";
import Pagination from "../../infrastructure/components/Pagination"

import classNames from "classnames";

import styles from "./trades-page.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TradesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0)

  const tokenInHeader = useSelector(getTokenInHeaders);

  useEffect(() => {
    tokenInHeader && fetchTrades();
  }, [searchText, tokenInHeader]);

  useEffect(() => {
    fetchTrades();
  }, [currentPage, pageSize]);

  const fetchTrades = () => {
    setIsFetching(true);
    axios
      .get(
        `/trades?offset=${currentPage * pageSize
        }&count=${pageSize}&searchAll=${searchText}`
      )
      .then((res) => {
        const {
          data: { tradeList, totalCount },
        } = res;

        const tradeData = []
        tradeList.forEach((t, id) => tradeData.push({ ...t, id }))
        setRows(tradeData || []);
        
        setTotalCount(totalCount)
        setIsFetching(false);
      })
      .catch((err) => {
        dispatch(setPageReloaded());
      });
  };

  const renderTradesCells = () => {
    return [
      {
        field: "tradeId",
        headerName: "ID " + t("Trades"),
        flex: 1,
        headerClassName: styles.table_header,
      },
      {
        field: "orderId",
        headerName: "ID " + t("Orders"),
        flex: 1,
        headerClassName: styles.table_header,
      },
      {
        field: "orderType",
        headerName: t("Operation_type"),
        flex: 1,
        renderCell: ({ row }) => {
          return row.orderType === "BUY" ? t("Sale") : t("Buy");
        },
        headerClassName: styles.table_header,
      },
      {
        field: "fromAmount",
        headerName: t("Sale"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          if (!row.fromAmount.toString().includes(".")) {
            return `${row.fromAmount}.00 ${row.fromCcy}`;
          } else {
            return `${row.fromAmount} ${row.fromCcy}`;
          }
        },
      },
      {
        field: "toAmount",
        headerName: t("Buy"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          if (!row.fromAmount.toString().includes(".")) {
            return `${row.toAmount}.00 ${row.toCcy}`;
          } else {
            return `${row.toAmount} ${row.toCcy}`;
          }
        },
      },
      {
        field: "exchangeRate",
        headerName: t("Exchange_rate"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          if (!row.fixRate.toString().includes(".")) {
            return `${row.fixRate}.00 MDL`;
          } else {
            return `${row.fixRate} MDL`;
          }
        },
      },
      {
        field: "tradeState",
        headerName: t("Completed"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return row.tradeState === "SUCCESS"
            ? moment(row.whenCreated).format("DD/MM/YYYY HH:mm")
            : row.tradeState;
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page)
  }

  const pageSizeClick = (size) => {
    setCurrentPage(0)
    setIsFetching(true);
    setPageSize(size)
  }

  return (
    <AppMainLayout navBarTitle={t("Trades")}>
      <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
        <InputLabel htmlFor="outlined-adornment-amount">
          {t("Search")}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<SearchIcon />}
          label={t("Search")}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </FormControl>
      <div
        className={classNames({
          [styles.table_container]: true,
          [styles.is_fetching]: isFetching,
        })}
      >
        {rows.length > 0 || isFetching ? (
          <>
            <DataTableComponent
              columns={renderTradesCells()}
              checkboxSelection={false}
              className={styles.trades_page}
              isFetching={isFetching}
              idChangeValue={"id"}
              {...{ rows }}
            />
            {!isFetching && <Pagination
              onClick={paginationClick}
              onChangePageSize={pageSizeClick}
              total={totalCount}
              pageIndex={currentPage}
              pageSize={pageSize}
            />}
          </>

        ) : (
          <EmptyPageContent />
        )}
      </div>
      <div className={styles.customer_page_bottom_container}>
        <Button
          variant="secondary"
          size="l"
          icon={<ExitIcon />}
          label={t("Export_as_csv")}
          iconPosition="right"
          id={"EXPORT_CSV"}
          disabled={true}
        />
      </div>
    </AppMainLayout>
  );
};

export default TradesPage;
