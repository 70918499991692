export const LAST_MONTH = 1;
export const LAST_THREE_MONTHS = 2;
export const QUARTER = 3;
export const CUSTOM_PERIOD = 4;
export const LAST_HOUR = 5;
export const LAST_DAY = 6;

export const filterData = [
    { name: "all", showInDropDown: false },
    { name: "new", showInDropDown: true },
    { name: "invoiced", showInDropDown: true },
    { name: "overdue", showInDropDown: true },
    { name: "payed", showInDropDown: true },
    { name: "canceled", showInDropDown: true },
]

export const filters = ["all", "active", "completed", "deleted", "expired", "error"]

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const filterKycData = ["all", "filling", "filled", "bank received", "abandoned", "error"]

export const generateToken = () => {
    let token = "";
    const possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

    for (var i = 0; i < 50; i++)
        token += possible.charAt(Math.floor(Math.random() * possible.length));

    return token;
}

export const amountFormatter = (number) => Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');

// format as ### ###
export const numberFormatter = (number) => Number(number).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$& ');

export const otpInputs = [1, 2, 3, 4, 5, 6];

export const onlyNumbersRegex = (value) => {
    const regex = /^[0-9]+$/
    return regex.test(value)
}

const getRoundedNumber = (amount, number, letter) => {
    let result
    if (amount % number === 0) {
        result = `${(amount / number).toFixed(0)}${letter}`;
    } else {
        result = `${(amount / number).toFixed(1)}${letter}`;
    }

    return result
}

export const getShortAmount = (amount) => {
    let text
    if (amount > 0 && amount <= 999) {
        text = amount;
    } else if (amount >= 1000 && amount <= 999999) {
        text = getRoundedNumber(amount, 1000, "K");
    } else if (amount >= 1000000 && amount <= 999999999) {
        text = getRoundedNumber(amount, 1000000, "M");;
    } else if (amount > 0) {
        text = getRoundedNumber(amount, 1000000000, "B");;
    }

    return text
}