import React from 'react';

export const OrderPublishedIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#E7F1FF" />
            <path d="M26 20.0049C26 20.5549 26.45 21.0049 27 21.0049H29C29.55 21.0049 30 20.5549 30 20.0049C30 19.4549 29.55 19.0049 29 19.0049H27C26.45 19.0049 26 19.4549 26 20.0049Z" fill="#2F80ED" />
            <path d="M24.59 24.8249C24.26 25.2649 24.35 25.8749 24.79 26.1949C25.32 26.5849 25.88 27.0049 26.41 27.4049C26.85 27.7349 27.47 27.6449 27.79 27.2049C27.79 27.1949 27.8 27.1949 27.8 27.1849C28.13 26.7449 28.04 26.1249 27.6 25.8049C27.07 25.4049 26.51 24.9849 25.99 24.5949C25.55 24.2649 24.93 24.3649 24.6 24.8049C24.6 24.8149 24.59 24.8249 24.59 24.8249Z" fill="#2F80ED" />
            <path d="M27.81 12.8149C27.81 12.8049 27.8 12.8049 27.8 12.7949C27.47 12.3549 26.85 12.2649 26.42 12.5949C25.89 12.9949 25.32 13.4149 24.8 13.8149C24.36 14.1449 24.28 14.7649 24.61 15.1949C24.61 15.2049 24.62 15.2049 24.62 15.2149C24.95 15.6549 25.56 15.7449 26 15.4149C26.53 15.0249 27.09 14.5949 27.62 14.1949C28.05 13.8749 28.13 13.2549 27.81 12.8149Z" fill="#2F80ED" />
            <path d="M16 17.0049H12C10.9 17.0049 10 17.9049 10 19.0049V21.0049C10 22.1049 10.9 23.0049 12 23.0049H13V26.0049C13 26.5549 13.45 27.0049 14 27.0049C14.55 27.0049 15 26.5549 15 26.0049V23.0049H16L21 26.0049V14.0049L16 17.0049Z" fill="#2F80ED" />
            <path d="M23.5 20.0049C23.5 18.6749 22.92 17.4749 22 16.6549V23.3449C22.92 22.5349 23.5 21.3349 23.5 20.0049Z" fill="#2F80ED" />
        </svg>
    );
}; 