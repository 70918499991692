import {
  SET_CUSTOMER_INFO,
  UPLOAD_CUSTOMER_INFO,
  SHOW_SIDE_DRAWER,
  SET_ORDER_DETAILS,
  SET_MODE_STATUS,
  OPEN_NOTIFICATION_MODAL
} from "../actions";
import { AnyAction } from "redux";
import {
  fieldsGeneral,
  fieldsBillingInfo,
  fieldsSuperUser,
  fieldsCustomizeCustomer,
} from "../../constants/data";

const initialState = {
  customerData: {
    general: fieldsGeneral,
    superUsers: fieldsSuperUser,
    billingInfo: fieldsBillingInfo,
    customizeCustomer: fieldsCustomizeCustomer,
  },
  orderDetails: {},
  modeStatus: false,
  showSideDrawer: false,
  showNotificationModal: false,
};

export default function addressesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case UPLOAD_CUSTOMER_INFO:
      return {
        ...state,
        customerData: {
          ...action.payload,
        },
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: {
          ...action.payload,
        },
      };
    case SET_MODE_STATUS:
      return {
        ...state,
        modeStatus: action.payload
      };
    case OPEN_NOTIFICATION_MODAL:
      return {
        ...state,
        showNotificationModal: action.payload
      };

    case SHOW_SIDE_DRAWER:
      return {
        ...state,
        showSideDrawer: !state.showSideDrawer,
      };
    case SET_CUSTOMER_INFO:
      const block = action.payload.block;
      const stateName = action.payload.stateName;
      return {
        ...state,
        customerData: {
          ...state.customerData,
          [block]: {
            ...state.customerData[block],
            values: {
              ...state.customerData[block].values,
              [stateName]: action.payload.value,
            },
          },
        },
      };

    default:
      return state;
  }
}
