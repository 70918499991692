import {
    CHANGE_LANGUAGE,
    SET_CUSTOMER_INFO,
    UPLOAD_CUSTOMER_INFO,
    SHOW_SIDE_DRAWER,
    SET_ORDER_DETAILS,
    SET_MODE_STATUS,
    OPEN_NOTIFICATION_MODAL
} from '../actions';
import i18n from "../../i18n";
import { storePrefLang } from "../services/Prefs";
import moment from "moment";

export const setCustomerInfo = (stateName, value, block) => {
    return { type: SET_CUSTOMER_INFO, payload: { stateName, value, block } };
}

export const uploadCustomerDetails = (payload) => {
    return { type: UPLOAD_CUSTOMER_INFO, payload };
}

export const onShowSideDrawer = () => {
    return { type: SHOW_SIDE_DRAWER };
}

export const setOrderDetails = (data) => {
    return { type: SET_ORDER_DETAILS, payload: data };
}

export const openNotificationModal = (state) => {
    return { type: OPEN_NOTIFICATION_MODAL, payload: state };
}

export const setModeStatus = (state) => {
    return { type: SET_MODE_STATUS, payload: state};
}

export const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
    storePrefLang(lng);
    return { type: CHANGE_LANGUAGE, payload: lng };
}
