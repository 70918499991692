import React, { useState, useEffect } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
// import Button from '@mui/material/Button';
import { Button, CloseRemoveIcon } from "finergy-kit";
import { AddIcon } from "../../assets/icons/add-icon";
import { filters, capitalize } from "../../constants";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import {
  onShowSideDrawer,
  setOrderDetails,
} from "../../api/actionCreators/mainActionCreator";
import SideDrawerComponent from "../../infrastructure/components/SideDrawerComponent";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmptyPageContent from "../../infrastructure/components/EmptyPageContent";
import moment from "moment";
import axios from "../../api/services/Api";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getTokenInHeaders } from "../../api/selectors/tokenSelector";
import { getModeState } from "../../api/selectors/mainSelector";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { PencilIcon } from "../../svg-icons/icon-pencil";
import { TrashIcon } from "../../svg-icons/icon-trash";
import styles from "./orders.module.scss";
import { enqueueSnackbar } from "notistack";
import Pagination from "../../infrastructure/components/Pagination";
import { convertDecimalNumber } from "../../hooks";

// const config = require('../../config.json');

// createServer({
//     routes() {
//         companiesHandler(this, config.serviceURL)
//         this.passthrough()
//     }
// })

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Orders = () => {
  // const { rows } = mockLicensesData
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activationDate, setActivationDate] = useState();
  const [contractDate, setContractDate] = useState();
  const [quantityOfLicenses, setQuantityOfLicenses] = useState(null);
  const [contract, setContract] = useState("");
  const [comment, setComment] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [btnLabel, setBtnLabel] = useState("Save");
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [redirectId, setRedirectId] = useState(null);
  const [isRedirect, setIsRedirect] = useState(false);
  // const [test, setCount] = useState(0);
  const [filter, setFilter] = useState("all");
  const [redirectToAddNewOrder, setRedirectToAddNewOrder] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedOrder, setDeletedOrder] = useState(null);
  // useEffect(() => {
  //     fetchOrders()
  // }, [searchText])

  const tokenInHeader = useSelector(getTokenInHeaders);
  const modeState = useSelector(getModeState);

  const fetchOrders = () => {
    const f = filter === "all" ? "" : filter;
    axios
      .get(
        `/orders?offset=${
          currentPage * pageSize
        }&count=${pageSize}&orderState=${f.toUpperCase()}`
      )
      .then((res) => {
        const { data } = res;
        setRows(data.orderList);
        setTotalCount(data.totalCount);
        setIsFetching(false);
        // setCount((test) => test + 1);
      })
      .catch(() => {});
  };

  useEffect(() => {
    setIsFetching(true);
    tokenInHeader && fetchOrders();
  }, [tokenInHeader, filter]);

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize]);

  const onCellClick = (row) => {
    dispatch(setOrderDetails(row));
    setRedirectId(row.orderId);
    setIsRedirect(true);
  };

  const hideAndResetData = () => {
    dispatch(onShowSideDrawer());
    setIsSaved(false);
    setBtnLabel("Saved");
  };

  const saveLicense = () => {
    setIsFetching(true);
    setTimeout(() => {
      setIsFetching(false);
      setIsSaved(true);
      setBtnLabel("Saved");

      setTimeout(() => {
        hideAndResetData();
      }, 500);
    }, 1000);
  };
  const renderLicensesCells = () => {
    return [
      {
        field: "orderId",
        headerName: "ID " + t("Orders"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          return `${row.orderId}`;
        },
      },
      {
        field: "orderType",
        headerName: t("Operation_type"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          return row.orderType === "BUY" ? t("Buy") : t("Sale");
        },
      },
      {
        field: "сcysType",
        headerName: t("Currency"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          return `${row.fromCcy} → ${row.toCcy}`;
        },
      },
      {
        field: "exchangeRate",
        headerName: t("Exchange_rate"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          if (!row.fixRate.toString().includes(".")) {
            return `${row.fixRate}.00 MDL`;
          } else {
            return `${row.fixRate.toFixed(2)} MDL`;
          }
        },
      },
      {
        field: "initialAmount",
        headerName: t("Сurrent_amount"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          if (!row.initialAmount.toString().includes(".")) {
            return `${row.initialAmount}.00 ${row.fromCcy}`;
          } else {
            return `${convertDecimalNumber(row.initialAmount)} ${row.fromCcy}`;
          }
        },
      },
      {
        field: "currentAmount",
        headerName: t("Rest"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          if (!row.currentAmount.toString().includes(".")) {
            return `${row.currentAmount}.00 ${row.fromCcy}`;
          } else {
            return `${convertDecimalNumber(row.currentAmount)} ${row.fromCcy}`;
          }
        },
      },
      {
        field: "whenCreated",
        headerName: t("Created"),
        flex: 1,
        headerClassName: styles.table_header,
        // cellClassName: row.orderState === "ERROR" ? styles.order_status_error : '',
        cellClassName: ({ row }) => {
          return row.orderState === "ERROR" ? styles.order_status_error : "";
        },
        renderCell: ({ row }) => {
          return moment(row.whenCreated).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "orderState",
        headerName: t("Status"),
        flex: 1,
        headerClassName: `${styles.table_header} ${styles.header_action_buttons}`,
        cellClassName: styles.order_status,
        renderCell: ({ row }) => {
          const isOrderStateProccesing =
            row.orderState === "ACTIVE" && row.isPartiallyCompleted === true
              ? "PROCESSING"
              : row.orderState;

          // const label = capitalize(isOrderStateProccesing.toLowerCase());
          return UseErrorLabel(isOrderStateProccesing);
        },
      },
      {
        field: "activity",
        headerName: "",
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.action_buttons,
        renderCell: (row) => {
          return !modeState ? renderActivity(row) : null;
        },
      },
    ];
  };

  const renderActivity = ({ row }) => {
    const orderIsActive = row.orderState.toLowerCase() === "active";
    return (
      <div className={styles.action_icons}>
        {orderIsActive && !row.isPartiallyCompleted && (
          <PencilIcon
            onClick={() => onCellClick(row)}
            className={styles.pencil_icon}
          />
        )}
        {(orderIsActive || (row.isPartiallyCompleted && orderIsActive)) && (
          <TrashIcon
            onClick={() => shodDeleteModalAndSaveId(row)}
            className={styles.trash_icon}
          />
        )}
      </div>
    );
  };

  const shodDeleteModalAndSaveId = (row) => {
    setDeletedOrder(row);
    setShowDeleteModal(true);
  };

  const onDeleteCompany = () => {
    axios.put(`/order/cancel/${deletedOrder.orderId}`).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setShowDeleteModal(false);
       // enqueueSnackbar(t("Order_is_cancelled"));
        fetchOrders();
      } else {
        enqueueSnackbar(t("Market_off"), { variant: "warning" });
        fetchOrders()
        // setRedirectToOrdersPage(true);
      }
      
    });
  };

  const renderDeleteModal = () => {
    return (
      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowDeleteModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <CloseRemoveIcon
            className={styles.close_icon}
            onClick={() => setShowDeleteModal(false)}
          />
          <DialogTitle>
            <div>
              <h3>{t('Order_canceld_title')}</h3>
              {t("Are_you_sure_to_cancel_order")}{" "}
              <strong>#{deletedOrder.orderId}</strong> 
              {deletedOrder.orderType === "BUY"
                ? t("On_Order_buy")
                : t("On_Order_sell")}{" "}
              {convertDecimalNumber(deletedOrder.initialAmount)}{" "}
              {deletedOrder.fromCcy} {t("By_curs")}{" "}
              {deletedOrder.fixRate.toFixed(2)} MDL?
            </div>
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              onClick={() => onDeleteCompany()}
              variant="tertiary"
              className={styles.cancel_button}
              label={t("Delete_order")}
              size="l"
              iconPosition="off"
              id={"ON_DELETE_MODAL"}
            />
            <Button
              onClick={() => setShowDeleteModal(false)}
              variant="tertiary"
              label={t("Back")}
              size="l"
              iconPosition="off"
              id={"CANCEL_DELETE_MODAL"}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const renderLicesnseDetails = () => {
    return (
      <SideDrawerComponent
        title={"Add new licenses"}
        onClick={() => saveLicense()}
        isFetching={isFetching}
        isSaved={isSaved}
        btnLabel={btnLabel}
      >
        <div className={styles.licenses_container}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Quantity of licenses*
            </InputLabel>
            <Select
              className="select-lang-selector"
              label="Quantity of licenses*"
              value={quantityOfLicenses}
              onChange={(e) => setQuantityOfLicenses(e.target.value)}
            >
              <MenuItem value={100} key={0}>
                100
              </MenuItem>
              <MenuItem value={500} key={1}>
                500
              </MenuItem>
              <MenuItem value={1000} key={2}>
                1000
              </MenuItem>
              <MenuItem value={1500} key={3}>
                1500
              </MenuItem>
              <MenuItem value={2000} key={4}>
                2000
              </MenuItem>
              <MenuItem value={2500} key={5}>
                2500
              </MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Activation date"
                value={activationDate}
                onChange={(newValue) => setActivationDate(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>

          <TextField
            style={{ width: "100%" }}
            className="text-input"
            placeholder={"Contract #"}
            id="outlined-basic"
            variant="outlined"
            label={"Contract #"}
            type={"text"}
            value={contract}
            onChange={(e) => setContract(e.target.value)}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Contract date"
                value={contractDate}
                onChange={(newValue) => setContractDate(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>

          <TextField
            style={{ width: "100%" }}
            className={styles.textarea}
            placeholder={"Comment"}
            id="outlined-basic"
            variant="outlined"
            multiline
            rows={7}
            label={"Comment"}
            type={"text"}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </SideDrawerComponent>
    );
  };

  const renderFilterItems = (noBorder = false) => {
    const content = filters.map((name) => {
      return (
        <button
          className={classNames({
            [`${styles.filter_button}`]: !noBorder,
            [`${styles.filter_button_active}`]: filter === name,
            [`${styles.filter_dropdown}`]: noBorder,
            [`${styles.status_label}`]: noBorder,
          })}
          onClick={() => setFilter(name)}
        >
          {t(capitalize(name))}
        </button>
      );
    });

    return content;
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setCurrentPage(0);
    setIsFetching(true);
    setPageSize(size);
  };

  return (
    <AppMainLayout navBarTitle={t("Orders")}>
      {renderLicesnseDetails()}

      {redirectToAddNewOrder && <Redirect to={`/app/add-order`} />}
      {redirectId && <Redirect to={`/app/order/details/${redirectId}`} />}
      {showDeleteModal && renderDeleteModal()}

      <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
        <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<SearchIcon />}
          label="Search"
        />
      </FormControl>

      <div className={styles.calendar_and_filters_container}>
        {/* <FormControl className={styles.select_period}>
                    <CalendarIcon />
                    <label> Period:</label>
                    <Select
                        value={period}
                        name="selected-period"
                        onChange={(e) => setPeriod(Number(e.target.value))}
                    >
                        <MenuItem value={LAST_MONTH}>Last month</MenuItem>
                        <MenuItem value={LAST_THREE_MONTHS}>Last three months</MenuItem>
                        <MenuItem value={QUARTER}>Quarter</MenuItem>
                        <Divider />
                        <MenuItem value={CUSTOM_PERIOD}>Custom period</MenuItem>
                    </Select>
                </FormControl> */}
        <div className={styles.filter_container}>{renderFilterItems()}</div>
      </div>

      {rows.length > 0 || isFetching ? (
        <>
          <DataTableComponent
            // onCellClick={(row) => onCellClick(row)}
            checkboxSelection={false}
            columns={renderLicensesCells()}
            isFetching={isFetching}
            idChangeValue={"orderId"}
            {...{ rows }}
          />
          {!isFetching && (
            <Pagination
              onClick={paginationClick}
              onChangePageSize={pageSizeClick}
              total={totalCount}
              pageIndex={currentPage}
              pageSize={pageSize}
            />
          )}
        </>
      ) : (
        <EmptyPageContent />
      )}

      <div className={styles.customer_page_bottom_container}>
        {/* <Button
                    variant="secondary"
                    size="l"
                    icon={<ExitIcon />}
                    label={t('Export_as_csv')}
                    iconPosition="right"
                    id={"EXPORT_CSV"}
                /> */}
        <Button
          className={classNames({
            [`${styles.blue_btn}`]: true,
            [`${styles.is_disabled}`]: modeState,
          })}
          size="l"
          icon={<AddIcon />}
          label={t("Add_order")}
          id={"ADD_NEW_ORDER"}
          onClick={() => (modeState ? {} : setRedirectToAddNewOrder(true))}
          disabled={modeState} 
          variant={""}        
        />
      </div>
    </AppMainLayout>
  );
};

export default Orders;
