import React from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { IDataTableComponentProps } from "./types"
import CircularProgress from '@mui/material/CircularProgress';
import styles from './data-table.module.scss';
import classNames from "classnames"

function DataTableComponent({
    rows,
    columns,
    onCellClick,
    checkboxSelection = true,
    toolbar,
    isFetching = false,
    className,
    idChangeValue
}: IDataTableComponentProps) {

    return (
        <div
            className={classNames({
                [`${styles.table_container}`]: true,
                [`${styles.is_loading}`]: isFetching,
                [`${className}`]: true
            })}
            id="data_grid_table_wrapper"
        >
            {isFetching ?
                <CircularProgress className="circular-progress" size={60} /> :
                <DataGrid
                    getRowId={(row) => row[idChangeValue]}
                    rows={rows || []}
                    columns={columns || []}
                    // initialState={{
                    //     pagination: {
                    //         paginationModel: { page: 0, pageSize: 10 },
                    //     },
                    // }}
                    hideFooterPagination={true}
                    hideFooter={true}
                    // pageSizeOptions={[5, 10]}
                    checkboxSelection={checkboxSelection}
                    onCellClick={onCellClick}
                    slots={{
                        toolbar,
                    }}
                    sx={{ fontFamily: "OnestMedium" }}
                />}
        </div>

    );
};

export default DataTableComponent;
