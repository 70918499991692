import React, { useState, useEffect } from "react";
import { ISignInPageContent } from "../../interfaces";
import Heading from "../../infrastructure/components/Heading";
import FormLabeledInput from "../../infrastructure/components/FormComponents/FormLabeledInput";
import ButtonComponent from "../../infrastructure/components/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  signIn,
  authStart,
  authEnd,
  setToken,
  setExpiredTime,
  setTokenInHeaders,
  setTokenExpired,
} from "../../api/actionCreators/authActionCreator";
import classNames from "classnames"
import {
  getIsAuthFetching,
  getAuthData,
} from "../../api/selectors/authSelector";
import {
  getToken,
  getAccessToken,
  getTokenInHeaders,
} from "../../api/selectors/tokenSelector";
import CircularProgress from "@mui/material/CircularProgress";
import { Redirect } from "react-router-dom";
import axios from "../../api/services/Api";
import {
  storeToken,
  storeUser,
  storeExpiredTime,
  storeRefreshToken,
} from "../../api/services/Auth";
import { Logo } from "../../assets/icons/logo";
import moment from "moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { PersonIcon, KeyIcon, EyeIcon, LanguageIcon } from "finergy-kit";
import { getCurrentLanguage } from "../../api/selectors/languageSelector";
import { changeLanguage } from "../../api/actionCreators/mainActionCreator";
import { useTranslation } from "react-i18next";
import { otpInputs, onlyNumbersRegex } from "../../constants/";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { enqueueSnackbar } from "notistack";

import styles from "./signin-page.module.scss";

const SignInPageContent = (props: ISignInPageContent) => {
  const dispatch = useDispatch();

  // const {authCode } = useParams();
  // const authCode = code.get("authCode"); 

  const isAuthFetching = useSelector(getIsAuthFetching);
  const token = useSelector(getToken);
  const accessToken = useSelector(getAccessToken);
  const data = useSelector(getAuthData);
  const language = useSelector(getCurrentLanguage);
  const tokenInHeader = useSelector(getTokenInHeaders);
  const { t } = useTranslation();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [authCodeFetching, setAuthCodeFetching] = useState(false);
  const [error, setError] = useState("");
  const [digitsError, setDigitsError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  // const [isAuthCodeVerifing, setIsAuthCodeVerifing] = useState(true);
  const [currentLanguage, setcurrentLanguage] = useState(language);
  const [newOtp, setNewOtp] = useState({});

  const handleLanguageChange = (lang) => {
    setcurrentLanguage(lang);
    changeLanguage(lang);
  };

  // const connect = (myToken) => {
  //   // setIsSocketAdded(true)
  //   console.log("connect+++++++++++++++ ", myToken)
  //   const socket = new SockJS('http://34.147.135.197:9090/ws'); // Update with your WebSocket endpoint
  //   const client = Stomp.over(socket);
  //   client.connect({ 'Auth-Token': myToken }, frame => {
  //     // setConnected(true);
  //     console.log('Connected: ' + frame);
  //     client.subscribe('/topic/notifications', message => {
  //       console.log("/topic----------", message)
  //       // showGreeting(message.body);
  //     });
  //     client.subscribe('/user/queue/notifications', message => {
  //       // console.log("/user--------", JSON.parse(message.command))
  //       const res = JSON.parse(message.body)
  //       enqueueSnackbar(res.bodyRu)
  //       // showGreeting(res);
  //     });
  //   });
  //   // setStompClient(client);
  // };

  const onLogin = () => {
    setError("");
    dispatch(authStart());
    const params = {
      login,
      password,
    };

    axios
      .post("/login", { ...params })
      .then((res: any) => {
        const {
          data: { accessToken, expiresIn, refreshToken },
        } = res;
        if (res.status === 200) {
          const tokenData = {
            accessToken,
            expiresIn,
            refreshToken,
          };
          // connect(accessToken)
          storeToken(accessToken);
          storeRefreshToken(refreshToken);

          const updatedDate = moment().add(30, "seconds");
          storeExpiredTime(updatedDate);
          storeUser(JSON.stringify({ login }));

          dispatch(signIn(JSON.stringify({ login })));
          dispatch(setToken(tokenData));
          dispatch(setExpiredTime(updatedDate.toDate()));
          dispatch(authEnd());
          dispatch(setTokenInHeaders(true));
          dispatch(setTokenExpired(false));
          setRedirect(true);
        } else {
          setError(t("Wrong_details"));
          dispatch(authEnd());
        }
      })
      .catch((error) => {
        setError(t("Wrong_details"));
        dispatch(authEnd());
      });
  };

  useEffect(() => {
    const authCode = new URLSearchParams((new URL(window.location.href).search)).get('authCode')

    if (authCode && !token) {
      setAuthCodeFetching(true)


      axios
        .post("/login/authcode", { authCode })
        .then((res: any) => {
          const {
            data: { accessToken, expiresIn, refreshToken },
          } = res;
          if (res.status === 200 || res.status === 201) {
            const tokenData = {
              accessToken,
              expiresIn,
              refreshToken,
            };
            storeToken(accessToken);
            storeRefreshToken(refreshToken);

            const updatedDate = moment().add(30, "seconds");
            storeExpiredTime(updatedDate);
            storeUser(JSON.stringify({ login: "" }));

            dispatch(signIn(JSON.stringify({ login: "" })));
            dispatch(setToken(tokenData));
            dispatch(setExpiredTime(updatedDate.toDate()));
            dispatch(authEnd());
            dispatch(setTokenInHeaders(true));
            dispatch(setTokenExpired(false));
            //setRedirect(true);
            // setAuthCodeFetching(false)
            window.location.reload();
            
          } else {
            setError(t("Wrong_details"));
            dispatch(authEnd());
          }
        })
        .catch((error) => {
          setError(t("Wrong_details"));
          dispatch(authEnd());
        });
    }
  }, [])

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.code === "Enter" && !tokenInHeader) {
        event.preventDefault();
        onLogin();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [login, password]);

  useEffect(() => {
    token && accessToken && setRedirect(true);
  }, [token]);

  const setOtp = (value, index) => {
    setError("");
    setDigitsError(false);

    const isValid = onlyNumbersRegex(value);
    if (isValid || value === "") {
      const checkedOtp = {
        ...newOtp,
        [index]: value,
      };
      setNewOtp(checkedOtp);

      setTimeout(() => {
        if (index !== 5) {
          setTimeout(() => {
            document.getElementById(`OTP-INPUT-${index + 1}`).focus();
          }, 10);
        } else {
          // checkOtp(checkedOtp)
        }
      });
    } else {
      setDigitsError(true);
    }
  };

  const renderOtpInputs = () => {
    const content = otpInputs.map((i, index) => {
      return (
        <TextField
          className="text-input-otp"
          id={`OTP-INPUT-${index}`}
          onChange={(e) => setOtp(e.target.value, index)}
          variant="outlined"
          placeholder="0"
          disabled={index === 0 ? false : !newOtp[index - 1]}
          value={newOtp[index] || ""}
          inputProps={{
            maxLength: 1,
          }}
        />
      );
    });

    return content;
  };

  const renderOTPView = () => {
    return (
      <div className={styles.forgotPasswordWrapper}>
        <Heading headingFirst={t("Восстановление пароля")} />
        <Heading
          textRegular={t(
            "На номер телефона +373 68 5***28 было отправлено смс с кодом ОТП. Введите ниже, код из полученного смс."
          )}
          className={`${styles.mb_24} ${styles.mt_16} `}
        />
        <div className="otp-container">
          <div className="justify-align-center">{renderOtpInputs()}</div>
        </div>
        <ButtonComponent
          title={t("Далее")}
          id={'NEXT_OTP_BUTTON'}
          onClick={() => onLogin()}
          disabled={isAuthFetching}
          icon={<CircularProgress className="circular-progress" size={18} />}
        />
      </div>
    );
  };

  const renderForgotPasswordView = () => {
    return (
      <div className={styles.forgotPasswordWrapper}>
        <Heading headingFirst={t("Восстановление пароля")} />
        <Heading
          textRegular={t("Для начала сброса пароля, укажите ваш IDNP")}
          className={`${styles.mb_24} ${styles.mt_16} `}
        />
        <FormLabeledInput
          label="IDNP"
          type="text"
          id={"IDNP"}
          iconLeft={<PersonIcon />}
          onFocus={() => setError("")}
          onChange={(e) => setLogin(e.target.value)}
        />
        <ButtonComponent
          title={t("Далее")}
          id={'NEXT_FORGOT_PASS_BUTTON'}
          onClick={() => onLogin()}
          disabled={isAuthFetching}
          icon={<CircularProgress className="circular-progress" size={18} />}
        />
      </div>
    );
  };

  return (
    <div
      className={classNames({
        [`${styles.mainLoginSection}`]: true,
        [`${styles.short_auth}`]: authCodeFetching,
      })}
    >
      {authCodeFetching && <CircularProgress className="circular-progress" size={48} />}
      {redirect && <Redirect to="/app/invoices" />}
      <div className={styles.grid_wrapper}
        style={{ display: authCodeFetching ? "none" : "grid" }}
      >
        <div className={styles.bg_image_container}>
          <Logo />
        </div>

        <div className={styles.signin_container}>
          <div className={styles.main_container}>
            <div className={styles.select_lang_container_parent}>
              <FormControl className={styles.select_lang_container}>
                {/* <InputLabel id="demo-simple-select-label">{t('Employment')}</InputLabel> */}
                <LanguageIcon />
                <Select
                  className="select-lang-selector"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentLanguage}
                  onChange={(e) => handleLanguageChange(e.target.value)}
                >
                  <MenuItem value={"ro"} key={0} id="LANG-ITEM-RO">
                    RO
                  </MenuItem>
                  <MenuItem value={"ru"} key={1} id="LANG-ITEM-RU">
                    RU
                  </MenuItem>
                  <MenuItem value={"en"} key={2} id="LANG-ITEM-EN">
                    EN
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            {!forgotPassword && (
              <>
                <Heading headingFirst={t("Authentification")} />
                <Heading
                  textRegular={t("Fill_field_above")}
                  className={`${styles.mb_24} ${styles.mt_16} `}
                />
                <FormLabeledInput
                  label="Email"
                  type="text"
                  value={login}
                  id={"EMAIL"}
                  iconLeft={<PersonIcon />}
                  onFocus={() => setError("")}
                  onChange={(e) => setLogin(e.target.value)}
                />
                <FormLabeledInput
                  type="password"
                  value={password}
                  id={"PASSWORD"}
                  onFocus={() => setError("")}
                  iconLeft={<KeyIcon />}
                  iconRight={<EyeIcon />}
                  onChange={(e) => setPassword(e.target.value)}
                  label={t("Password")}
                  showPasswordText
                />

                {/* Remove  for MVP */}
                {/* <div className={styles.forget_password} onClick={() => setForgotPassword(true)}>
                                    {t('Забыли пароль?')}
                                </div> */}

                <ButtonComponent
                  title={t("Log_in")}
                  id={'LOG_IN_BUTTON'}
                  onClick={() => onLogin()}
                  disabled={isAuthFetching}
                  icon={
                    <CircularProgress className="circular-progress" size={18} />
                  }
                />

                {error && <div className={styles.error_container}>{error}</div>}
              </>
            )}
            {/* {forgotPassword && renderForgotPasswordView()} */}
          </div>

          <div className={styles.copyright}>
            © 2024 FXDIRECT. {t("All_rights_reserved")}.
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignInPageContent;
