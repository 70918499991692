import React, { useState, useEffect, useRef } from 'react';
import { INotificationModalProps } from "./types"
import classNames from "classnames"
import CircularProgress from '@mui/material/CircularProgress';
import axios from "../../../../api/services/Api";
import NotificationItem from "./notification-item"

import styles from './notification-modal.module.scss';

function useOutsideAlerter(ref, setIsOpenNotifications) {
    useEffect(() => {
        function handleClickOutside(event) {

            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpenNotifications(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


const NorificationModal = ({ onClick }: INotificationModalProps) => {

    const [isFetching, setIsFetching] = useState(false);

    const [notifList, setNotifList] = useState([]);
    const [lastId, setLastId] = useState(""); 
    const wrapperRef = useRef(null);
    const [isAtBottom, setIsAtBottom] = useState(false);



    const fetchNotifications = (startId = "", onScroll = true) => {
        setLastId(startId)
        setIsFetching(true)

        axios
            .get(`/notifications?count=5&startFromId=${startId}`)
            .then((res) => {
                const { data } = res;
                const list = onScroll ? [...notifList, ...data.notificationList] : [...data.notificationList]
                setNotifList([...list])

                setIsAtBottom(false);
                onScroll && setTimeout(() => {
                    
                    const { scrollHeight } = document.getElementById("notificationModalContent");
                    document.getElementById("notificationModalContent").scrollTo({
                        top: scrollHeight - 545,
                        behavior: 'smooth',
                    });
                }, 100);

                setIsFetching(false);

               
            })
            .catch(() => { });
    };


    useEffect(() => {

        const startId = notifList.length === 0 ? "" : notifList[notifList.length - 1]?.notificationId

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.getElementById("notificationModalContent");
            const bottom = scrollTop + clientHeight === scrollHeight;
            bottom && !isAtBottom && fetchNotifications(startId)
            setIsAtBottom(bottom);
        };

        const myScrollContent = document.getElementById("notificationModalContent");
        myScrollContent.addEventListener('scroll', handleScroll);

        return () => {
            myScrollContent.removeEventListener('scroll', handleScroll);
        };
    }, [isAtBottom, notifList])

    useOutsideAlerter(wrapperRef, onClick);

    useEffect(() => {
        setIsFetching(true);
        fetchNotifications();
    }, []);


    const renderNotifList = () => {
        const list = notifList.map((item, i) => {
            return <NotificationItem {...item} isNotifModal key={i}/>
        })

        return list
    }

    const setUnredMessages = () => {
        axios
            .put(`/notifications/read/${notifList[0].notificationId}`)
            .then((res) => {
                setIsFetching(true);
                fetchNotifications("", false);
            })
            .catch(() => { });
    }

    const checkIfIsUnread = notifList.find(item => !item.isRead)

    return (
        <div className={styles.notification_content} ref={wrapperRef}>
            <div className={styles.notif_modal_title_container}>
                <div className={styles.notif_modal_title}>Notifications</div>

                <div
                    className={classNames({
                        [`${styles.mars_as_read}`]: true,
                        [`${styles.mars_as_read_disabled}`]: !checkIfIsUnread || isFetching,
                        [`${styles.mars_as_read_is_clicked}`]: checkIfIsUnread,
                    })}
                    onClick={() => !checkIfIsUnread ? {} : setUnredMessages()}
                >
                    Marks all as read
                </div>
            </div>
            <div
                className={classNames({
                    [`${styles.notif_modal_content}`]: true,
                    [`${styles.is_loading}`]: isFetching,
                })}
                id="notificationModalContent"
            >
                {isFetching ? <CircularProgress className="circular-progress" size={60} /> : renderNotifList()}

                {/* {isAtBottom && scrollIsBottom &&
                    <div className={styles.preloader_notif}>
                        <CircularProgress className="circular-progress" size={30} />
                    </div>
                } */}
            </div>

        </div>
    );
};

export default NorificationModal;
