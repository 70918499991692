import React from 'react';
import { ISideDrawerComponentProps } from "./types"
import Drawer from '@mui/material/Drawer';
import { getSideDrawerOpenState } from '../../../api/selectors/mainSelector'
import { useDispatch } from 'react-redux';
import { onShowSideDrawer } from '../../../api/actionCreators/mainActionCreator';
import { CloseRemoveIcon } from '../../../svg-icons/icon-close-remove';
import { CheckedIcon } from '../../../svg-icons/icon-checked';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { SaveIcon } from "../../../svg-icons/icon-save"
import CircularProgress from '@mui/material/CircularProgress';
import classNames from "classnames"
import styles from './side-drawer.module.scss';


function SideDrawerComponent({
    children,
    title,
    hideButtons = false,
    btnLabel = "Save",
    onClick,
    isFetching,
    isSaved,
    noPadding = false
}: ISideDrawerComponentProps) {

    const dispatch = useDispatch();
    const isOpen = useSelector(getSideDrawerOpenState)

    return (
        <Drawer
            anchor={"right"}
            open={isOpen}
            onClose={() => dispatch(onShowSideDrawer())}
        >
            <div className={styles.settings_drawer_wrapper}>
                <div className={styles.drawer_title_container}>
                    <div className={styles.drawer_title}>{title}</div>
                    <IconButton onClick={() => dispatch(onShowSideDrawer())} className={styles.drawer_close_icon}>
                        <CloseRemoveIcon />
                    </IconButton>
                </div>
                <div
                    className={classNames({
                        [`${styles.drawer_content}`]: true,
                        [`${styles.no_padding}`]: noPadding,
                    })}
                >
                    {children}
                </div>
                {!hideButtons &&
                    <div className={styles.buttons}>
                        <Button
                            variant="outlined"
                            onClick={() => (!isFetching && !isSaved) ? onClick() : {}}
                            disabled={isFetching}
                            className={classNames({
                                [`${styles.no_margin}`]: isFetching,
                                [`${styles.saved}`]: isSaved,
                            })}
                        >
                            {btnLabel}
                            {!isFetching && !isSaved ? <SaveIcon /> :
                                isSaved ? <CheckedIcon /> :
                                    <CircularProgress size={20} sx={{ margin: 0 }} />}
                        </Button>
                    </div>
                }
            </div>
        </Drawer>

    )
};

export default SideDrawerComponent;
