import React from 'react';

export const OrderCanceledIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#F4F5F7" />
            <path d="M20 10C14.47 10 10 14.47 10 20C10 25.53 14.47 30 20 30C25.53 30 30 25.53 30 20C30 14.47 25.53 10 20 10ZM24.3 24.3C23.91 24.69 23.28 24.69 22.89 24.3L20 21.41L17.11 24.3C16.72 24.69 16.09 24.69 15.7 24.3C15.31 23.91 15.31 23.28 15.7 22.89L18.59 20L15.7 17.11C15.31 16.72 15.31 16.09 15.7 15.7C16.09 15.31 16.72 15.31 17.11 15.7L20 18.59L22.89 15.7C23.28 15.31 23.91 15.31 24.3 15.7C24.69 16.09 24.69 16.72 24.3 17.11L21.41 20L24.3 22.89C24.68 23.27 24.68 23.91 24.3 24.3Z" fill="#A1ABB2" />
        </svg>

    );
}; 