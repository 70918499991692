import React from "react";
import { IStatisticContainerProps } from "./types";
import classNames from "classnames";
import styles from "./statistic-container.module.scss";
// import { useSelector } from 'react-redux';
// import { getCurrentLanguage } from '../../../api/selectors/languageSelector';
import { useTranslation } from "react-i18next";
import { amountFormatter } from "../../../constants";

function StatisticContainer({
  title,
  invoiceNumber,
  amount,
  currency,
  color,
  onlyNumber = false,
  id,
}: IStatisticContainerProps) {
  // const language = useSelector(getCurrentLanguage);
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        [`${styles.statistic_container}`]: true,
      })}
      id={id}
    >
      <div className={`${styles.statistic_title}`}>{title}</div>

      <div className={`${styles.statistic_info_wrapper}`}>
        <div className={`${styles.statistic_info_label}`}>
          {t("Account_number")}:&nbsp;
        </div>
        <div className={`${styles.statistic_currency}`}>{invoiceNumber}</div>
      </div>
      <div className={`${styles.statistic_info_wrapper}`}>
        <div className={`${styles.statistic_info_label}`}>
          {t("Balance")}:&nbsp;
        </div>
        <div className={`${styles.statistic_amount}`} style={{ color }}>
          {amountFormatter(amount)}
          {!onlyNumber && <span>{currency}</span>}
        </div>
      </div>
      <div className={`${styles.statistic_info_wrapper}`}>
        <div className={`${styles.statistic_info_label}`}>
          {t("Currency")}:&nbsp;
        </div>
        <div className={`${styles.statistic_currency}`}>{currency}</div>
      </div>
    </div>
  );
}

export default StatisticContainer;
