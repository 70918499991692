import React from "react";

export const AccountBalanceIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="account_balance">
        <path
          id="Vector"
          d="M4.5 11.9633V15.9633C4.5 16.7933 5.17 17.4633 6 17.4633C6.83 17.4633 7.5 16.7933 7.5 15.9633V11.9633C7.5 11.1333 6.83 10.4633 6 10.4633C5.17 10.4633 4.5 11.1333 4.5 11.9633ZM10.5 11.9633V15.9633C10.5 16.7933 11.17 17.4633 12 17.4633C12.83 17.4633 13.5 16.7933 13.5 15.9633V11.9633C13.5 11.1333 12.83 10.4633 12 10.4633C11.17 10.4633 10.5 11.1333 10.5 11.9633ZM4 22.4633H20C20.83 22.4633 21.5 21.7933 21.5 20.9633C21.5 20.1333 20.83 19.4633 20 19.4633H4C3.17 19.4633 2.5 20.1333 2.5 20.9633C2.5 21.7933 3.17 22.4633 4 22.4633ZM16.5 11.9633V15.9633C16.5 16.7933 17.17 17.4633 18 17.4633C18.83 17.4633 19.5 16.7933 19.5 15.9633V11.9633C19.5 11.1333 18.83 10.4633 18 10.4633C17.17 10.4633 16.5 11.1333 16.5 11.9633ZM11.07 1.95333L3.17 6.11333C2.76 6.32333 2.5 6.75332 2.5 7.21333C2.5 7.90333 3.06 8.46333 3.75 8.46333H20.26C20.94 8.46333 21.5 7.90333 21.5 7.21333C21.5 6.75332 21.24 6.32333 20.83 6.11333L12.93 1.95333C12.35 1.64333 11.65 1.64333 11.07 1.95333Z"
          fill="#A1ABB2"
        />
      </g>
    </svg>
  );
};
