import React from 'react';

export const CheckedIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.17605 15.7754L5.99174 13.5912C5.49841 13.0979 4.70148 13.0979 4.20814 13.5912C3.7148 14.0845 3.7148 14.8815 4.20814 15.3748L7.29057 18.4571C7.78391 18.9505 8.58084 18.9505 9.07418 18.4571L19.7921 7.73955C20.2854 7.24621 20.2854 6.44928 19.7921 5.95594C19.2987 5.4626 18.5018 5.4626 18.0085 5.95594L8.17605 15.7754Z" fill="#EBF0F7" />
        </svg>


    );
};