import React, { useState, useEffect } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import { getOrderDetails } from "../../api/selectors/mainSelector";
import { setOrderDetails } from "../../api/actionCreators/mainActionCreator";
import DropDownComponent from "../../infrastructure/components/DropDownComponent";
import TooltipWrapper from "../../infrastructure/components/TooltipWrapper";
import axios from "../../api/services/Api";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { HelpIcon } from "../../svg-icons/icon-help";
import { ApproveOrderIcon } from "../../svg-icons/icon-approve-order";
import { Button } from "finergy-kit";
import {
  amountFormatter,
  numberFormatter,
  getShortAmount,
} from "../../constants";
import { Redirect } from "react-router-dom";
import EmptyPageContent from "../../infrastructure/components/EmptyPageContent";

import styles from "./add-order.module.scss";
import { convertDecimalNumber } from "../../hooks";
import { enqueueSnackbar } from "notistack";

const AddOrder = () => {
  const { orderId } = useParams();

  const emptyCountData = {
    accountId: null,
    balance: 0,
    currency: "",
    number: "",
  };
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("buy");
  const [currencyOne, setCurrencyOne] = useState(emptyCountData);
  const [currencyTwo, setCurrencyTwo] = useState(emptyCountData);

  const [stakanData, setStakanData] = useState({ marketElements: [] });
  const [summ, setSumm] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [createdOrderId, setCreatedOrderId] = useState(null);
  const [options, setOptions] = useState([]);

  const [maxBuyNumber, setMaxBuyNumber] = useState(0);
  const [maxSellNumber, setMaxSellNumber] = useState(0);

  const [isFetching, setIsFetching] = useState(true);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [isCancelingOrder, setIsCancelingOrder] = useState(false);
  const [isAddingNewOrder, setIsAddingNewOrder] = useState(false);
  const [isFetchingComission, setIsFetchingComission] = useState(false);
  const [isCreatingNewOrder, setIsCreatingNewOrder] = useState(false);

  const [redirectToOrdersPage, setRedirectToOrdersPage] = useState(false);
  const [approveOrder, setApproveOrder] = useState(false);
  const [oldData, setOldData] = useState({
    currencyOne: emptyCountData,
    summ: "",
    exchange: "",
  });

  const [comission, setComission] = useState(null);
  const [finalAmount, setFinalAmount] = useState(null);

  const dispatch = useDispatch();
  const orderDetails = useSelector(getOrderDetails);

  const isBuyTab = activeTab === "buy";
  const statisticLabel = currencyOne?.currency;
  const ccyByDefault = "USD";

  const disabled = !currencyOne || !currencyTwo || !summ || !exchange;

  useEffect(() => {
    if (orderId > 0) {
      // setSumm(convertDecimalNumber(orderDetails.currentAmount));
      setSumm(orderDetails.currentAmount);
      setActiveTab(orderDetails?.orderType?.toLowerCase() || "buy");
      setExchange(orderDetails?.fixRate?.toFixed(2));
      const countSelectedItem = options.filter(
        (o) => o.currency === orderDetails.fromCcy
      );
      setCurrencyOne(countSelectedItem[0]);

      setOldData({
        currencyOne: countSelectedItem[0],
        summ: orderDetails.currentAmount,
        exchange: orderDetails?.fixRate?.toFixed(2),
      });
    } else {
      dispatch(setOrderDetails({}));
    }
  }, [orderId, options]);

  useEffect(() => {
    axios.get("/accounts?requestFrom=CREATE_ORDER").then((res) => {
      if (res.status === 200) {
        setOptions(res.data.accountList);
        setCountsList(res.data.accountList);
      }
    });
  }, []);

  const setCountsList = (list = []) => {
    const listMdl = list.filter((op) => op.currency === "MDL");
    const listNotMdl = list.filter((op) => op.currency !== "MDL");

    setCurrencyTwo(listMdl[0]);
    setCurrencyOne(listNotMdl[0]);
  };

  useEffect(() => {
    setIsFetching(true);
    const url = `/order/market?fromCcy=${
      currencyOne?.currency || orderDetails.fromCcy || ccyByDefault
    }`;
    axios.get(url).then((res) => {
      if (res.status === 200) {
        const sell = [];
        const buy = [];
        res.data.marketElements.forEach((el) => {
          sell.push(el.sellAmount);
          buy.push(el.buyAmount);
        });

        setMaxBuyNumber(Math.max(...buy));
        setMaxSellNumber(Math.max(...sell));
        setStakanData(res.data);

        setTimeout(() => {
          setIsFetching(false);
        }, 1000);
      }
    });
  }, [currencyOne]);

  useEffect(() => {
    const params = {
      orderType: activeTab.toLocaleUpperCase(),
      fromCcy: statisticLabel,
      toCcy: "MDL",
      fixRate: exchange,
      fromAmount: summ,
    };

    if (
      currencyOne?.number &&
      currencyTwo?.number &&
      summ > 0 &&
      Number(exchange) > 0
    ) {
      setIsFetchingComission(true);
      axios.post("/order/create/preview", { ...params }).then((res) => {
        setComission(res.data.commissionAmount || 0);
        setFinalAmount(res.data.toAmount || 0);
        setTimeout(() => {
          setIsFetchingComission(false);
        }, 1000);
      });
    }
  }, [currencyOne, currencyTwo, summ, exchange]);

  const onApproveOrder = () => {
    setIsCreatingOrder(true);

    const params = {
      orderType: activeTab.toUpperCase(),
      fromCcy: currencyOne.currency,
      toCcy: currencyTwo.currency,
      fixRate: Number(exchange),
      fromAmount: Number(summ),
      fromAccountId: Number(currencyOne.accountId),
      toAccountId: Number(currencyTwo.accountId),
    };

    if (orderId > 0) {
      axios.put(`/order/update/${orderId}`, { ...params }).then((res) => {
        if (res.status === 200 || res.status === 201) {
          setIsCreatingNewOrder(true);
          setTimeout(() => {
            setIsAddingNewOrder(false);
            setRedirectToOrdersPage(true);
          }, 3000);
        } else {
          enqueueSnackbar(t("Market_off"), { variant: "warning" });
          setIsCreatingOrder(false);
          setRedirectToOrdersPage(false);
        }
      });
    } else {
      axios.post("/order/create", { ...params }).then((res: any) => {
        if (res?.data?.orderId) {
          setCreatedOrderId(res?.data.orderId);
          setApproveOrder(true);
          setIsCreatingOrder(false);
        } else {
          enqueueSnackbar(t("Market_off"), { variant: "warning" });
          setIsCreatingOrder(false);
          // setRedirectToOrdersPage(true);
        }
      });
    }
  };

  const onCancelOrder = () => {
    setIsCancelingOrder(true);
    axios.put(`/order/cancel/${createdOrderId}`).then(() => {
      setApproveOrder(false);
      setIsCancelingOrder(false);
    });
  };

  const setOrder = async () => {
    setIsAddingNewOrder(true);
    const params = {
      orderId: createdOrderId,
    };

    // axios.post(`/order/confirm`, { ...params })
    try {
      const response = await axios.post(`/order/confirm`, { ...params });
      if (response.status === 200 || response.status === 201) {
        setIsCreatingNewOrder(true);
        setTimeout(() => {
          setIsAddingNewOrder(false);
          setRedirectToOrdersPage(true);
        }, 1000);
      } else {
        enqueueSnackbar(t("Market_off"), { variant: "warning" });
        setIsCreatingOrder(false);
        setIsCancelingOrder(false);
        setIsAddingNewOrder(false);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const renderTooltipText = () => {
    return (
      <ol>
        <li>{t("Tooltip_1_line")}</li>
        <li>{t("Tooltip_2_line")}</li>
      </ol>
    );
  };

  const checkPercentFromMax = (number, max) => {
    return (100 * number) / max;
  };

  const renderExchange = () => {
    const content = (stakanData?.marketElements || []).map((el, index) => {
      const { fixRate, sellAmount, buyAmount } = el;

      const buyPercent = checkPercentFromMax(buyAmount, maxBuyNumber);
      const sellPercent = checkPercentFromMax(sellAmount, maxSellNumber);

      return (
        <div
          className={classNames({
            [`${styles.statistic_line}`]: true,
          })}
          key={index}
          id="statistic_line"
        >
          <div className={styles.statistic_buy} id="statistic_buy">
            <b>{getShortAmount(buyAmount) || "-"}</b>
            {buyAmount > 0 && (
              <div
                style={{
                  height: "100%",
                  width: `${buyPercent}%`,
                  backgroundColor: "#E7FFEF",
                  borderRight: "2px solid #20B053",
                }}
                className={styles.gradient_box}
              />
            )}
          </div>
          <div className={styles.statistic_exchange} id="statistic_exchange">
            {amountFormatter(fixRate)}
          </div>
          <div className={styles.statistic_sell} id="statistic_sell">
            {sellAmount > 0 && (
              <div
                style={{
                  height: "100%",
                  width: `${sellPercent}%`,
                  backgroundColor: "#FFF3F3",
                  borderRight: "2px solid #F19898",
                }}
                className={styles.gradient_box}
              />
            )}
            <b>{getShortAmount(sellAmount) || "-"}</b>
          </div>
        </div>
      );
    });

    return content;
  };

  const validationAndSet = (value, action) => {
    //const re = /^[1-9]+\.?[0-9]*$/;
    // const valueNumber = Number(value)
    if (value > 0 || value === "") {
      action(value);
    }
  };
  const comissionInfo = () => {
    return (
      <>
        <div className={styles.additional_info} id="additional_info">
          <div className={styles.additional_info_label} id="additional_info_label">{t("Comission")}</div>
          <div className={styles.additional_info_text} id="additional_info_text">
            {comission ? (
              <>
                <div id="comission">
                  <b>{numberFormatter(comission)}</b>
                  <span>MDL</span>
                </div>

                <TooltipWrapper text={renderTooltipText()} mt0>
                  <IconButton className="info-btn">
                    <HelpIcon />
                  </IconButton>
                </TooltipWrapper>
              </>
            ) : (
              " - "
            )}
          </div>
        </div>

        <div className={styles.additional_info}>
          <div className={styles.additional_info_label}>{t("Summ_in_lei")}</div>
          <div className={styles.additional_info_big_text}>
            {finalAmount ? (
              <div id="final_amount">
                <b>≈ {numberFormatter(finalAmount)} </b>
                <span>MDL</span>
              </div>
            ) : (
              " - "
            )}
          </div>
        </div>
      </>
    );
  };

  const disableButton =
    oldData.currencyOne?.currency !== currencyOne?.currency ||
    oldData.summ !== summ ||
    Number(oldData.exchange) !== Number(exchange);

  const renderNewOrderContent = () => {
    return (
      <div className={styles.new_order_container} id="new_order_container">
        <div className={styles.buy_sell_container} id="buy_sell_container">
          <div className={styles.tabs_items_container} id="tabs_items_container">
            <div
              className={classNames({
                [`${styles.tabs_item}`]: true,
                [`${styles.active}`]: isBuyTab,
                [`${styles.disabled}`]: orderId > 0 ? true : false,
              })}
              onClick={() => setActiveTab("buy")}
              id="tabs_item_buy"
            >
              {t("Buy_label")}
            </div>
            <div
              className={classNames({
                [`${styles.tabs_item}`]: true,
                [`${styles.active}`]: activeTab === "sell",
                [`${styles.disabled}`]: orderId > 0,
              })}
              onClick={() => setActiveTab("sell")}
              id="tabs_item_sell"
            >
              {t("Sell_label")}
            </div>
          </div>

          <div className={styles.tabs_content}>
            <div
              className={classNames({
                [`${styles.tab_content_item}`]: true,
                [`${styles.tab_content_item_padding}`]: true,
                [`${styles.active_content}`]: true,
              })}
            >
              <div className={styles.sell_count_container}>
                <div className={styles.count_label}>
                  {isBuyTab ? t("Currency_buy") : t("Currency_sell")}
                </div>
                <div className={styles.count_dropdown}>
                  <DropDownComponent
                    options={options.filter((op) => op.currency !== "MDL")}
                    selectedItem={currencyOne}
                    onSelectItem={(i) => setCurrencyOne(i)}
                    activeTab={activeTab}
                    id="count_dropdown"
                  />
                </div>
              </div>

              <div className={styles.sell_count_container}>
                <div className={styles.count_label}>
                  {isBuyTab ? t("Summ_buy") : t("Summ_sell")}
                </div>
                <div className={styles.count_dropdown}>
                  <TextField
                    placeholder={"50 000"}
                    variant={"outlined"}
                    type={"text"}
                    id={"SUMM"}
                    className={styles.summ_styles}
                    value={summ && convertDecimalNumber(summ)}
                    onChange={(e) => validationAndSet(e.target.value, setSumm)}
                  />
                </div>
              </div>

              <div className={styles.sell_count_container}>
                <div className={styles.count_label}>
                  {isBuyTab ? t("Currency_sell") : t("Currency_buy")}
                </div>
                <div className={styles.count_dropdown}>
                  <DropDownComponent
                    options={options.filter((op) => op.currency === "MDL")}
                    activeTab={activeTab}
                    selectedItem={currencyTwo}
                    onSelectItem={(i) => setCurrencyTwo(i)}
                    onlyMdl
                    disabled
                    id="CURRENCY_TWO"
                  />
                </div>
              </div>

              <div className={styles.sell_count_container}>
                <div className={styles.count_label}>{t("Curs_exchange")}</div>
                <div className={styles.count_dropdown}>
                  <TextField
                    placeholder={"19.48"}
                    onChange={(e) =>
                      validationAndSet(e.target.value, setExchange)
                    }
                    variant={"outlined"}
                    type={"text"}
                    id={"SUMM_FIRST"}
                    className={styles.summ_styles}
                    value={exchange}
                  />
                </div>
              </div>
              <div
                className={classNames({
                  [`${styles.commision_content}`]: true,
                  [`${styles.is_loading}`]: isFetchingComission,
                })}
              >
                {isFetchingComission ? (
                  <CircularProgress size={20} />
                ) : (
                  comissionInfo()
                )}
              </div>

              <div className={styles.add_order_action_buttons}>
                <Button
                  className={classNames({
                    [`${styles.blue_btn}`]: !disabled,
                    [`${styles.is_disabled}`]: disabled || !disableButton,
                  })}
                  variant="secondary"
                  size="l"
                  label={orderId > 0 ? t("Edit_order") : t("Publish_order")}
                  iconPosition="off"
                  id={"ADD_NEW_ORDER"}
                  onClick={() =>
                    disabled || !disableButton ? {} : onApproveOrder()
                  }
                  disabled={disabled || isCancelingOrder || !disableButton}
                />

                <Button
                  size="l"
                  label={t("Decline_order")}
                  iconPosition="off"
                  onClick={() => setRedirectToOrdersPage(true)}
                  id={"CANCEL_NEW_ORDER"}
                  variant={"secondary"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.stakan}>
          <div className={styles.add_order_volume_title}>
            {t("Depth_of_market")}
          </div>
          <div className={styles.statistic_container}>
            <div className={styles.statistic_topbar}>
              <div
                className={classNames({
                  [`${styles.statistic_line}`]: true,
                  [`${styles.is_header}`]: true,
                })}
              >
                <div>
                  <span>{t("Buy_label")}</span>
                  <span>{t("Volumes_label")}</span>
                </div>
                <div>
                  <span>{t("Exchange_curs")} </span>
                  <span> ({statisticLabel || ccyByDefault}-MDL)</span>
                </div>
                <div>
                  <span>{t("Sell_label")}</span>
                  <span> {t("Volumes_label")}</span>
                </div>
              </div>
            </div>
            <div
              className={classNames({
                [`${styles.statistic_bottombar}`]: true,
                [`${styles.content_centered}`]: isFetching,
              })}
            >
              {isFetching ? (
                <CircularProgress size={40} />
              ) : stakanData.marketElements.length > 0 ? (
                renderExchange()
              ) : (
                <EmptyPageContent />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderApproveOrderContent = () => {
    return (
      <div className={styles.approve_order_container}>
        {isCreatingNewOrder ? (
          <CircularProgress size={40} />
        ) : (
          <>
            <ApproveOrderIcon />
            <h2>{t("Approve_publish_order")}</h2>
            <div className={styles.approve_order_content} id={"APPROVE_ORDER_CONTENT"}>
              {!isBuyTab ? t("Order_to") : t("Order_from")}
              <b>
                {" "}
                {numberFormatter(convertDecimalNumber(summ))} {statisticLabel}
              </b>
              <br />
              {t("By_curs")}{" "}
              <b>
                1.00 {statisticLabel} = {amountFormatter(exchange)} MDL
              </b>
            </div>
            <div className={styles.add_order_action_buttons}>
              <Button
                variant="primary"
                size="l"
                label={t("Apply")}
                iconPosition="off"
                id={"ADD_NEW_ORDER"}
                onClick={() => setOrder()}
                disabled={isCancelingOrder || isAddingNewOrder}
              />

              <Button
                size="l"
                label={t("Decline_order")}
                iconPosition="off"
                onClick={() => onCancelOrder()}
                id={"CANCEL_NEW_ORDER"}
                variant={"secondary"}
                disabled={isCancelingOrder || isAddingNewOrder}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <AppMainLayout navBarTitle={t("Orders")} noBackground>
      {redirectToOrdersPage && <Redirect to={`/app/orders`} />}
      <div className={`${styles.statistic_parent}`}>
        {!approveOrder ? (
          isCreatingOrder ? (
            <CircularProgress size={40} />
          ) : (
            renderNewOrderContent()
          )
        ) : (
          renderApproveOrderContent()
        )}
      </div>
    </AppMainLayout>
  );
};

export default AddOrder;
