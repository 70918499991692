import { getUserData } from "../services/Auth";
import {
  AUTH_START,
  AUTH_FINISH,
  SET_AUTH_DATA,
  SET_TOKEN,
  LOGOUT_START,
  AUTH_STOP,
} from "../actions";
import { AnyAction } from "redux";

const initialState = {
  authData: getUserData(),
  token: "",
  isAuthFetching: false,
};

export default function addressesReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case LOGOUT_START:
    case AUTH_START:
      return {
        ...state,
        isAuthFetching: true,
      };
    case AUTH_STOP:
    case AUTH_FINISH:
      return {
        ...state,
        isAuthFetching: false,
      };

    case SET_AUTH_DATA:
      return {
        ...state,
        authData: action.payload,
      };

    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
}
