import { RootState } from '../../interfaces'

const getMainReducerState = (state: RootState) => {
    return state?.MainReducer;
};

export const getCustomerInfo = (state: RootState) => {
    return getMainReducerState(state).customerData || {};
};

export const getSettingsState = (state: RootState) => {
    return getMainReducerState(state).settings;
}

export const getSideDrawerOpenState = (state: RootState) => {
    return getMainReducerState(state).showSideDrawer;
}
export const getNotificationModalState = (state: RootState) => {
    return getMainReducerState(state).showNotificationModal;
}
export const getOrderDetails = (state: RootState) => {
    return getMainReducerState(state).orderDetails;
}

export const getModeState = (state: RootState) => {
    return getMainReducerState(state).modeStatus;
}