import React, { useState, useRef, useEffect } from 'react';
import { Logo } from '../../../assets/icons/logo';
import { IDropDownComponentProps } from "./types"
import classNames from "classnames"
import { navigation } from "../../../constants/data"
import styles from './dropdown.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { getCurrentLanguage } from '../../../api/selectors/languageSelector';
// import { changeLanguage } from '../../../api/actionCreators/mainActionCreator';
import { ArrowIcon } from "../../../svg-icons/icon-arrow"
import { CheckedIcon } from '../../../svg-icons/icon-checked';

function useOutsideAlerter(ref, setOpen) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


function DropDownComponent({ options, selectedItem, onSelectItem, onlyMdl, disabled, activeTab, id }: IDropDownComponentProps) {

    const [open, setOpen] = useState(false)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setOpen);

    const renderOptions = () => {
        const list = onlyMdl ? options.filter(op => op?.currency === "MDL") : options.filter(op => op?.currency !== "MDL")
        const content = list.map((op, i) => {
            const { currency, number } = op
            return (
                <div
                    className={classNames({
                        [`${styles.option_container}`]: true,
                        [`${styles.count_information}`]: true,
                        [`${styles.selected_count}`]: Number(selectedItem?.number) === Number(number),
                    })}
                    onClick={() => onSelectItem(op)}
                >

                    <label>{currency}</label>

                    <div className={styles.count_information}>
                        <span>Счёт: {number} </span>
                        {Number(selectedItem?.number) === Number(number) && <CheckedIcon />}
                    </div>

                </div>
            )
        })

        return content
    }

    return (
        <div
            ref={wrapperRef}
            onClick={() => setOpen(!open)}
            className={classNames({
                [`${styles.nav_wrapper}`]: true,
                [`${styles.dropdown_focused}`]: !disabled ? open : false,
                [`${styles.dropdown_disabled}`]: disabled
            })}
            id={id}
            >
            <div className={styles.selected_item}>

                <label>{selectedItem?.currency}</label>
                <div className={styles.count_information}>
                    <span>Счёт: {selectedItem?.number} </span>
                    <ArrowIcon onClick={() => { }} />
                </div>

                {open && !disabled && <div className={styles.count_options}>
                    {renderOptions()}
                </div>}

            </div>
        </div>
    );
};

export default DropDownComponent;
