import React from 'react';

export const ApproveOrderIcon = () => {
    return (
        <svg width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="108" height="108" rx="54" transform="matrix(1 0 0 -1 0 108)" fill="url(#paint0_linear_842_29111)" />
            <path d="M69 54.0116C69 55.3866 70.125 56.5116 71.5 56.5116H76.5C77.875 56.5116 79 55.3866 79 54.0116C79 52.6366 77.875 51.5116 76.5 51.5116H71.5C70.125 51.5116 69 52.6366 69 54.0116Z" fill="#CCD3DD" />
            <path d="M65.475 66.0616C64.65 67.1616 64.875 68.6866 65.975 69.4866C67.3 70.4616 68.7 71.5116 70.025 72.5116C71.125 73.3366 72.675 73.1116 73.475 72.0116C73.475 71.9866 73.5 71.9866 73.5 71.9616C74.325 70.8616 74.1 69.3116 73 68.5116C71.675 67.5116 70.275 66.4616 68.975 65.4866C67.875 64.6616 66.325 64.9116 65.5 66.0116C65.5 66.0366 65.475 66.0616 65.475 66.0616Z" fill="#CCD3DD" />
            <path d="M73.525 36.0366C73.525 36.0116 73.5 36.0116 73.5 35.9866C72.675 34.8866 71.125 34.6616 70.05 35.4866C68.725 36.4866 67.3 37.5366 66 38.5366C64.9 39.3616 64.7 40.9116 65.525 41.9866C65.525 42.0116 65.55 42.0116 65.55 42.0366C66.375 43.1366 67.9 43.3616 69 42.5366C70.325 41.5616 71.725 40.4866 73.05 39.4866C74.125 38.6866 74.325 37.1366 73.525 36.0366Z" fill="#CCD3DD" />
            <path d="M44 46.5116H34C31.25 46.5116 29 48.7616 29 51.5116V56.5116C29 59.2616 31.25 61.5116 34 61.5116H36.5V69.0116C36.5 70.3866 37.625 71.5116 39 71.5116C40.375 71.5116 41.5 70.3866 41.5 69.0116V61.5116H44L56.5 69.0116V39.0116L44 46.5116Z" fill="#CCD3DD" />
            <path d="M62.75 54.0116C62.75 50.6866 61.3 47.6866 59 45.6366V62.3616C61.3 60.3366 62.75 57.3366 62.75 54.0116Z" fill="#CCD3DD" />
            <defs>
                <linearGradient id="paint0_linear_842_29111" x1="54" y1="0" x2="54" y2="108" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4F5F7" />
                    <stop offset="1" stopColor="#F4F5F7" />
                </linearGradient>
            </defs>
        </svg>
    );
};