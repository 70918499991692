import React, { useState, useEffect } from 'react';
import { INotificationItemProps } from "./types"
import { OrderPublishedIcon } from "../../../../../svg-icons/notifications/icon-order-published";
import { CommonIcon } from "../../../../../svg-icons/notifications/icon-common";
import { ErrorIcon } from "../../../../../svg-icons/notifications/icon-error";
import { OrderCanceledIcon } from "../../../../../svg-icons/notifications/icon-order-canceled";
// import { OrderExpiredIcon } from "../../../../../svg-icons/notifications/icon-order-expired";
import { SuccessIcon } from "../../../../../svg-icons/notifications/icon-success";
import { getCurrentLanguage } from "../../../../../api/selectors/languageSelector";
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames"
import { openNotificationModal } from "../../../../../api/actionCreators/mainActionCreator";
import moment from "moment"

import styles from './notification-item.module.scss';

const NotificationItem = ({
    notificationType,
    bodyEn,
    bodyRo,
    bodyRu,
    isRead,
    notificationId,
    titleEn,
    titleRo,
    titleRu,
    whenCreated,
    isNotifModal = false,
    showNotificationModal = false }: INotificationItemProps) => {

    const language = useSelector(getCurrentLanguage);
    const dispatch = useDispatch();

    const iconComponents = {
        NEW_ORDER: <OrderPublishedIcon />,
        CANCEL_ORDER: <OrderCanceledIcon />,
        EDIT_ORDER: <SuccessIcon />,
        TRADE_COMPLETED: <SuccessIcon />,
        TRADE_ERROR: <ErrorIcon />,
        ERROR: <ErrorIcon />,
        DEFAULT: <CommonIcon />,
    }
    const renderIcon = (type) => {
        const icon = iconComponents[type] || iconComponents.DEFAULT;
        return icon;
    }
    const getLocalizedValue = (language, values) => {
        const localizedValue = values[language] || values.en;
        return localizedValue;
    };

    const renderDescription = () =>
        getLocalizedValue(language, { en: bodyEn, ro: bodyRo, ru: bodyRu });

    const renderTitle = () =>
        getLocalizedValue(language, { en: titleEn, ro: titleRo, ru: titleRu });


    //
    return (
        <div
            className={classNames({
                [`${styles.notification_item}`]: true,
                [`${styles.notif_modal_styles}`]: isNotifModal,
                [`${styles.notif_cursor_pointer}`]: showNotificationModal,

            })}
            onClick={() => showNotificationModal ? dispatch(openNotificationModal(true)) : {}}
        >

            <div
                className={classNames({
                    [`${styles.notif_icon}`]: true,
                    [`${styles.notif_centered_icon}`]: !isNotifModal,
                })}
            >
                {renderIcon(notificationType)}
            </div>
            <div className={styles.notif_content}>
                <div className={styles.notif_title}>
                    {renderTitle()}
                    {isNotifModal && !isRead && <div className={styles.notif_is_read} />}
                </div>
                <div
                    className={classNames({
                        [`${styles.notif_description}`]: true,
                        [`${styles.no_mb}`]: !isNotifModal,
                    })}


                >
                    {renderDescription()}
                </div>
                {isNotifModal && <div className={styles.notif_time}>
                    {moment(whenCreated).format("HH:mm / DD-MM-YYYY")}
                </div>}
            </div>

        </div>
    );
};

export default NotificationItem;
