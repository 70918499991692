import React from 'react';

export const CommonIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#E7F1FF" />
            <path d="M14 16.4H26C26.55 16.4 27 15.95 27 15.4C27 14.85 26.55 14.4 26 14.4H14C13.45 14.4 13 14.85 13 15.4C13 15.95 13.45 16.4 14 16.4ZM19.17 27.15L14.37 19.95C13.92 19.29 14.4 18.4 15.2 18.4H24.8C25.6 18.4 26.08 19.29 25.63 19.95L20.83 27.15C20.44 27.75 19.56 27.75 19.17 27.15Z" fill="#2F80ED" />
        </svg>

    );
};